import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Navigation from "./navigation/Navigation";
import vector from "../../assets/images/Vector.png";
import VectorTablet from "../../assets/images/vector-tablet.png";
import tablet from "../../assets/images/Model.png";
import CodeTablet from "../../assets/images/code-tablet.png";
import CodeMobile from "../../assets/images/code-mobile.png";

const Header = () => {
  const [navbarScroll, setNavbarScroll] = useState(false);
  // const [currentPage, setCurrentPage] = useState("");
  const [tabletImage, setTabletImage] = useState(tablet);
  const [vectorImage, setVectorImage] = useState(vector);

  useEffect(() => {
    changeImages();
    changeBackground();

    window.addEventListener("scroll", changeBackground);
    window.addEventListener("resize", changeImages);
  });

  // useEffect(() => {
  //   setCurrentPage(window.location.pathname);
  // }, []);

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbarScroll(true);
    } else {
      setNavbarScroll(false);
    }
  };

  const changeImages = () => {
    if (window.screen.width > 440 && window.screen.width <= 1024) {
      setTabletImage(CodeTablet);
      setVectorImage(VectorTablet);
      return;
    }

    if (window.screen.width <= 440) {
      setTabletImage(CodeMobile);
      return;
    }

    setTabletImage(tablet);
    setVectorImage(vector);
    return;
  };

  return (
    <Wrapper id="header">
      <TopHorizontalLine isScrolling={navbarScroll}>
        {/* <Navigation navbarScroll={navbarScroll} currentPage={currentPage} /> */}
        <Navigation navbarScroll={navbarScroll} />
      </TopHorizontalLine>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background-color: #f5fcff;
`;

const HeroWrapper = styled.div`
  background-color: #f5fcff;
  position: relative;
`;

const TabletImage = styled.img`
  position: absolute;
  width: 1030px;
  height: 665px;
  left: 350px;
  top: 150px;
`;

const TopHorizontalLine = styled.div<any>`
  /* border-bottom: ${(props) =>
    props.isScrolling
      ? `1px solid rgba(172, 194, 226, 1)`
      : `1px dashed rgba(172, 194, 226, 1)`}; */
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  background: ${(props) =>
    props.isScrolling ? `rgba(255, 255, 255, 0.9)` : ""};
  box-shadow: ${(props) =>
    props.isScrolling
      ? `box-shadow: 0px 4px 36px rgba(55, 63, 107, 0.08)`
      : ""};
  backdrop-filter: ${(props) => (props.isScrolling ? `blur(4px)` : "")};
`;

const BottomHorizontalLine = styled.div`
  border-bottom: 1px dashed rgba(172, 194, 226, 1);
  bottom: 86px;
  position: absolute;
  width: 100%;
`;

const BackgroundImage = styled.img``;

const MainHeader = styled.h1`
  position: absolute;
  width: 30.375rem;
  height: 18rem;
  left: 7.5rem;
  top: 9.438rem;

  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 3.75rem;
  line-height: 4.5rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  color: #1c232c;

  @media screen and (max-width: 1024px) {
    margin: 0 0 -0.7rem 4.5rem;
    font-size: 48px;
    line-height: 60px;
    font-weight: 500;
  }

  @media screen and (max-width: 440px) {
    margin: 0 0 -0.7rem 1.2rem;
    font-size: 40px;
    line-height: 52px;
  }
`;

const Button = styled.button`
  position: absolute;
  width: 11.25rem;
  height: 1.75rem;
  left: 7.5rem;
  top: 29.813rem;

  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #1c232c;
  border: none;
  background-color: transparent;
`;

const SquareImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 5rem;
`;

export default Header;
