import React from "react";
import useMobileScreen from "../../hooks/useMobileScreen";
import { MOBILE_WIDTH_SCREEN } from "../../utils";
import { ExpertiseCard } from "../../components/layout-elements/cards/ExpertiseCard.component";
import { expertiseCardData } from "../../store/storeAllData";
import { OfferSection } from "../../components/proposal/ProposalSection.component";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

const BackendDevelopmentPage = () => {
  const isMobile = useMobileScreen(MOBILE_WIDTH_SCREEN);

  return (
    <>
      <Helmet>
        <title>Backend development - Kodin Soft</title>
        <meta
          name="description"
          content="Our backend devs create efficient, feature-rich apps. From custom APIs to DevOps, we ensure high performance & ease of management."
        />
        <link rel="canonical" href="https://www.kodinsoft.net/expertise/backend-development" />
      </Helmet>
      <Wrapper>
        <ExpertiseCard
          img={expertiseCardData.backend.image}
          alt={expertiseCardData.backend.alt}
          title={expertiseCardData.backend.title}
          description={expertiseCardData.backend.description}
          content={expertiseCardData.backend.content}
          techStack={expertiseCardData.backend.techStack}
        />
        <OfferSection isMobile={isMobile} />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 83%;
  margin: auto;
`;

export default BackendDevelopmentPage;
