import React from "react";
import styled from "@emotion/styled";
import NextLogo from "../../assets/images/tech-stack/nextjs.svg";
import ReactLogo from "../../assets/images/tech-stack/reactjs.svg";
import AngularLogo from "../../assets/images/tech-stack/angular.svg";
import FlutterLogo from "../../assets/images/tech-stack/flutter.svg";
import SwiftLogo from "../../assets/images/tech-stack/swift.svg";
import DotNetLogo from "../../assets/images/tech-stack/c-sharp.svg";
import JavaLogo from "../../assets/images/tech-stack/java.svg";
import NodeLogo from "../../assets/images/tech-stack/nodejs.svg";

import DockerLogo from "../../assets/images/tech-stack/docker-icon.svg";
import AwsLogo from "../../assets/images/tech-stack/aws.svg";
import AzureLogo from "../../assets/images/tech-stack/azure.svg";
import TextWithTitle from "../layout-elements/TextWithTitle.component";

const techStack = {
  frontend: {
    header: "Web",
    tech: [
      {
        name: "Next.js",
        image: NextLogo,
      },
      {
        name: "React",
        image: ReactLogo,
      },
      {
        name: "Angular",
        image: AngularLogo,
      },
    ],
  },
  mobile: {
    header: "Mobile",
    tech: [
      {
        name: "React Native",
        image: ReactLogo,
      },
      {
        name: "Flutter",
        image: FlutterLogo,
      },
      {
        name: "Swift",
        image: SwiftLogo,
      },
    ],
  },
  backend: {
    header: "Backend",
    tech: [
      {
        name: ".NET",
        image: DotNetLogo,
      },
      {
        name: "Java",
        image: JavaLogo,
      },
      {
        name: "Node.js",
        image: NodeLogo,
      },
    ],
  },
  infrastructure: {
    header: "Cloud native",
    tech: [
      {
        name: "Azure",
        image: AzureLogo,
      },
      {
        name: "AWS",
        image: AwsLogo,
      },
      {
        name: "Docker",
        image: DockerLogo,
      },
    ],
  },
} as any;

const customHeroTitleStyle = {
  title: {
    fontSize: "calc(56px + (56 - 48) * ((100vw - 1024px) / (1920 - 1024)))",
    lineHeight: "72px",
  },
  description: {
    fontSize: "calc(20px + (20 - 12) * ((100vw - 1024px) / (1920 - 1024)))",
    lineHeight: "34px",
    width: "95%",
  },
  container: {
    paddingTop: "60px",
  },
};

const TechStack = () => {
  const renderStackColumn = (
    key: string,
    hasRightBorder = false,
    hasLeftBorder = false
  ) => {
    const stack = techStack[key] as any;
    return (
      <TechStackItemWrapper>
        <TechStackHeader hasLeftBorder={hasLeftBorder}>
          {stack?.header}
        </TechStackHeader>
        {stack?.tech?.map((ts: any) => {
          return (
            <TechStackItem hasRightBorder={hasRightBorder} key={ts.name}>
              <TechImage
                src={ts.image}
                alt={ts.name}
                title={ts.name}
                width="auto"
                height="auto"
                loading="eager"
              />
              <TechName>{ts.name}</TechName>
            </TechStackItem>
          );
        })}
      </TechStackItemWrapper>
    );
  };

  return (
    <TechStackSection id="tech-stack">
      <TextWithTitle
        title="Tech Stack"
        description=""
        showButton={false}
        customStyles={customHeroTitleStyle}
      />

      <TechStackItems>
        {renderStackColumn("frontend", false, true)}
        {renderStackColumn("mobile")}
        {renderStackColumn("backend")}
        {renderStackColumn("infrastructure", true)}
      </TechStackItems>
    </TechStackSection>
  );
};

const TechStackSection = styled.section`
  background: #f8f9f9;
`;

const TechStackHeader = styled.div<any>`
  margin: 0;
  color: #1c232c;
  font-family: Outfit;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  border-left: ${(props) =>
    !props.hasLeftBorder ? `1px dashed rgba(172, 194, 226, 1)` : ""};
  border-bottom: 1px dashed rgba(172, 194, 226, 1);
  padding-bottom: 2rem;
  padding-top: 2rem;
  margin-left: -1px;
  text-align: center;

  @media screen and (max-width: 530px) {
    border-left: none;
  }
`;

const TechStackItems = styled.div`
  display: flex;
  margin: 2rem auto;

  @media screen and (max-width: 530px) {
    width: 89%;
    flex-direction: column;
    margin: 1rem auto;
  }
`;

const TechStackItemWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 530px) {
    margin-bottom: 1rem;
  }
`;

const TechStackItem = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #445062;
  aspect-ratio: 4 / 1;
  border-bottom: 1px dashed rgba(172, 194, 226, 1);
  border-right: ${(props) =>
    !props.hasRightBorder ? `1px dashed rgba(172, 194, 226, 1)` : ""};

  @media screen and (max-width: 530px) {
    border-right: none;
  }
`;

const TechImage = styled.img`
  width: 100px;
`;

const TechName = styled.p`
  white-space: nowrap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-left: 1rem;
`;

export default TechStack;
