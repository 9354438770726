import React from "react";
import styled from "@emotion/styled";

import ArrowSvg from "../../SVG/ArrowSvg";
import { Link } from "react-router-dom";
import { MOBILE_WIDTH_SCREEN } from "../../../utils";
import useMobileScreen from "../../../hooks/useMobileScreen";
import ProjectCard from "../ProjectCard.component";
import { cardProjectData } from "../../../store/storeAllData";

interface ParentProps {
  width?: any;
}

const ProjectComponent = ({ width = "83%" }: ParentProps) => {
  const isMobile = useMobileScreen(MOBILE_WIDTH_SCREEN);

  return (
    <Wrapper style={{ width: width }}>
      <TitleWrapper>
        <Title>&lt;Check some of our projects&gt;</Title>
        {!isMobile && (
          <Button>
            <Link to="/projects">
              See all projects <ArrowSvg fill="#2490f4" />
            </Link>
          </Button>
        )}
      </TitleWrapper>

      <ProjectsWrapper>
        {cardProjectData.slice(0, 2).map((item, i) => (
          <ProjectCard
            key={i}
            alt={item.alt}
            image={item.img}
            intro={item.content.intro}
            title={item.title}
            url={item.url}
            reverse={i % 2 === 1}
            stack={item.stack}
          />
        ))}
      </ProjectsWrapper>
      {isMobile && (
        <Button>
          <Link to="/projects">
            See all projects <ArrowSvg fill="#2490f4" />
          </Link>
        </Button>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  margin: auto;
  padding-bottom: 5.5rem;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Title = styled.h3`
  color: #1c232c;
  font-family: "Outfit";
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 3.75rem;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.375rem;

  background: linear-gradient(
    86.33deg,
    #1244c5 0.53%,
    #225aeb 50.08%,
    #2490f4 96.62%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  @media screen and (max-width: 1024px) {
    margin-top: 3rem;
  }
`;

const ProjectsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 3rem;
  row-gap: 3rem;
`;

export default ProjectComponent;
