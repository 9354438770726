import React, { FormEvent, useRef, useState } from "react";
import styled from "@emotion/styled";
import emailjs from "@emailjs/browser";

import MailIcon from "../../../assets/images/icons/mail.png";
import UserIcon from "../../../assets/images/icons/user.png";

const EMAILJS_SERVICE_ID: string = process.env.REACT_APP_EMAILJS_SERVICE_ID!;
const EMAILJS_TEMPLATE_ID: string = process.env.REACT_APP_EMAILJS_TEMPLATE_ID!;
const EMAILJS_PUBLIC_KEY: string = process.env.REACT_APP_EMAILJS_PUBLIC_KEY!;

const validateEmail = (email: string) => {
  const emailRegex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

const ContactForm = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: FormEvent) => {
    e.preventDefault();

    if (
      !form.current ||
      !form.current.user_name.value ||
      !form.current.user_email.value
    ) {
      setError("Please fill in all the required fields.");
      return;
    }

    const isValidEmail = validateEmail(form.current.user_email.value);
    if (!isValidEmail) {
      setError("Please enter a valid email address.");
      return;
    }

    if (form.current) {
      setIsLoading(true);
      emailjs
        .sendForm(
          EMAILJS_SERVICE_ID,
          EMAILJS_TEMPLATE_ID,
          form.current,
          EMAILJS_PUBLIC_KEY
        )
        .then(
          (result) => {
            setIsSuccess(true);
            setIsLoading(false);
            setError(null);
          },
          (error) => {
            setIsLoading(false);
            setError(
              "An error occurred while sending the email. Please try again."
            );
          }
        );
    }
  };
  return (
    <Wrapper>
      <Text>Contact form</Text>
      <form ref={form} onSubmit={sendEmail}>
        <InputWrapper>
          <CustomInput>
            <InputLabel>Your Name</InputLabel>
            <InputField
              name="user_name"
              icon={UserIcon}
              placeholder={"Enter your name"}
            />
          </CustomInput>
          <CustomInput>
            <InputLabel>Your E-mail</InputLabel>
            <InputField
              name="user_email"
              icon={MailIcon}
              placeholder={"Enter your e-mail"}
            />
          </CustomInput>
        </InputWrapper>
        <FormButton
          type="submit"
          value="Send"
          disabled={isLoading || isSuccess}
        >
          {isLoading ? "Sending..." : "Get in touch"}
        </FormButton>
      </form>
      {isSuccess && <Message>Your inquiry has been sent successfully</Message>}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 32%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 100;
  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 1.25rem;
  }
`;

const Text = styled.h4`
  color: white;
  font-family: "Outfit";
  font-size: calc(22px + (22 - 16) * ((100vw - 1024px) / (1920 - 1024)));
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */

  @media screen and (max-width: 440px) {
    width: 100%;
    flex-direction: column;
  }
`;

const CustomInput = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const InputLabel = styled.span`
  margin-bottom: 0.5rem;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #ffffff;
`;

const InputField = styled.input<any>`
  height: 3rem;

  border: none;
  /* background: #f5fcff url(${(props) => props.icon}) no-repeat 5% 50%; */

  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;

  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 0.875rem 1rem; */
  gap: 0.75rem;

  background: rgba(248, 249, 249, 0.12) url(${(props) => props.icon}) no-repeat
    5% 50%;
  box-shadow: 0px 2px 1rem rgba(20, 25, 53, 0.08);

  padding-left: 13%;

  ::placeholder {
    color: #6d819d;
  }

  color: white;

  :focus {
    /* outline: white; */
    outline: none;
    border: 1px solid white;
  }

  @media screen and (max-width: 440px) {
    width: 100%;
  }
`;

const FormButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 2.25rem;

  width: 11.25rem;
  height: 3.375rem;

  background: linear-gradient(
    87.07deg,
    #1244c5 -50.81%,
    #225aeb 31.15%,
    #2490f4 96.89%
  );

  box-shadow: 0px 8px 24px rgba(21, 24, 28, 0.12);
  backdrop-filter: blur(12px);
  color: #ffffff;
  padding: 0.5rem;
  font-size: 0.875rem;
  font-family: Outfit;
  border: none;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 440px) {
    margin-top: 0rem;
  }
`;

const Message = styled.span`
  margin-top: 0.5rem;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #ffffff;
`;

const ErrorMessage = styled.span`
  margin-top: 0.5rem;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: red;
`;

export default ContactForm;
