import * as React from "react";
import "./App.css";
import Footer from "./components/footer/Footer.component";
import Header from "./components/header/Header.component";

import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ProjectsPage from "./pages/ProjectsPage";
import ExpertisePage from "./pages/ExpertisePage";
import ServicesPage from "./pages/ServicesPage";
import ScrollToTop from "./components/layout-elements/ScrollToTop";
import MobileDevelopmentPage from "./pages/expertise/MobileDevelopmentPage";
import WebDevelopmentPage from "./pages/expertise/WebDevelopmentPage";
import BackendDevelopmentPage from "./pages/expertise/BackendDevelopmentPage";
import CloudNativeDevelopmentPage from "./pages/expertise/CloudNativeDevelopmentPage";
import ProjectDetailsPage from "./pages/ProjectDetailsPage";
import ApproachPage from "./pages/ApproachPage";

const App: React.FC = () => {
  return (
    <div className="App">
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/approach" element={<ApproachPage />} />
        <Route path="/expertise" element={<ExpertisePage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/projects/:id" element={<ProjectDetailsPage />} />
        <Route
          path="/expertise/web-development"
          element={<WebDevelopmentPage />}
        />
        <Route
          path="/expertise/mobile-development"
          element={<MobileDevelopmentPage />}
        />
        <Route
          path="/expertise/backend-development"
          element={<BackendDevelopmentPage />}
        />
        <Route
          path="/expertise/cloud-native-development"
          element={<CloudNativeDevelopmentPage />}
        />
      </Routes>

      <Footer />
    </div>
  );
};

export default App;
