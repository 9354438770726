import React from 'react';

import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { projectsData } from '../store/storeAllData';
import BackButton from '../components/BackButton.component';
import ProjectComponent from '../components/projects/simple-project-layout/ProjectComponent.component';
import { IProjectsData } from '../model/interfaces/projects/IProjectData';
import ContentSection from '../components/projectDetails/ContentSection.component';
import { Paragraph, Subtitle, Title } from '../components/projectDetails/styles';
import AdditionalSections from '../components/projectDetails/AdditionalSections.component';
import ImplementationSection from '../components/projectDetails/ImplementationSection.component';
import TechStackSection from '../components/projectDetails/TechStackSection.component';
import { Helmet } from 'react-helmet-async';

const ProjectDetailsPage = () => {
	const { id } = useParams();

	const { project, title, intro, img, content, techStack, additionalSections } = projectsData.find(
		(el) => el.url === id
	) as IProjectsData;

	return (
		<>
			<Helmet>
				<title>{project}</title>
				<meta name='description' content={title} />
				<link rel='canonical' href={`https://www.kodinsoft.net/${id}`} />
			</Helmet>
			<HeroContainer>
				<BackButton text='Projects' path='/projects' />
				<Image src={img} alt='cover-image' title='cover-image' width='auto' height='auto' loading='eager' />
			</HeroContainer>
			<Wrapper>
				<Container>
					<Title>{project}</Title>

					<Intro>
						<Subtitle>{title}</Subtitle>
						<Paragraph>{intro}</Paragraph>
					</Intro>

					<ContentSection
						title={content.client.title}
						img={content.client.img}
						alt={content.client.alt}
						content={content.client.content}
						floatDirection='left'
					/>

					<ContentSection
						title={content.success.title}
						img={content.success.img}
						alt={content.success.alt}
						content={content.success.content}
						bulletsDesc={content.success.bulletsDesc}
						floatDirection='right'
					/>

					<ImplementationSection {...content.implementation} />

					{additionalSections && <AdditionalSections additionalSections={additionalSections} />}
				</Container>
			</Wrapper>

			{techStack && <TechStackSection techStack={techStack} />}

			<ProjectComponent />
		</>
	);
};

const HeroContainer = styled.div`
	margin: 6rem auto 0 auto;
	width: 66%;

	@media screen and (max-width: 900px) {
		width: 100%;
	}
`;

const Wrapper = styled.div`
	width: 66%;
	margin: auto;
	@media screen and (max-width: 900px) {
		width: 85%;
	}
`;

const Container = styled.div`
	padding-top: 1rem;
	padding-bottom: 1rem;
`;
const Image = styled.img`
	width: 100%;
`;

const Intro = styled.div`
	padding-bottom: 1rem;
`;

export default ProjectDetailsPage;
