import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import NavItemProps from "../../../../model/interfaces/header/NavItemProps";
import { NavLink } from "react-router-dom";

const NavItem = (props: NavItemProps) => {
  const { link, children, style, isInvertStyle, isScrolling } = props;

  const NavItemWrapper = styled.li``;

  const NavLinkWrapper = styled(NavLink)<{
    "is-invert-style"?: string;
    "is-scrolling"?: string;
  }>`
    font-family: "Outfit", sans-serif;
    color: ${(props) =>
      props["is-invert-style"] && !props["is-scrolling"]
        ? "rgba(207, 213, 223, 1)"
        : "rgba(136, 141, 153, 1)"};

    text-decoration: none;
    font-size: 0.9rem;
    line-height: 1rem;
    &:hover {
      color: ${(props) =>
        props["is-invert-style"] && !props["is-scrolling"]
          ? "white"
          : "#1c232c"};
    }
    &.active {
      color: ${(props) =>
        props["is-invert-style"] && !props["is-scrolling"]
          ? "white"
          : "#1c232c"};
      font-weight: bold;

      & > span {
        ::before {
          content: "<";
        }
        ::after {
          content: ">";
        }
      }
    }
  `;

  const ActiveLink = styled.span`
    position: relative;

    ::before {
      position: absolute;
      top: 2px;
      left: -10px;
      content: "";
    }

    ::after {
      position: absolute;
      top: 2px;
      right: -10px;
      content: "";
    }
  `;

  return (
    <NavItemWrapper style={style}>
      <NavLinkWrapper
        to={link}
        is-invert-style={isInvertStyle ? "true" : undefined}
        is-scrolling={isScrolling ? "true" : undefined}
      >
        <ActiveLink>{children}</ActiveLink>
      </NavLinkWrapper>
    </NavItemWrapper>
  );
};
NavItem.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};
export default NavItem;
