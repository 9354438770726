import React from "react";
import styled from "@emotion/styled";
import TextWithTitle from "../layout-elements/TextWithTitle.component";

import { Link } from "react-router-dom";
import arrowSVG from "../../assets/images/arrow-right-new.svg";
import { MOBILE_WIDTH_SCREEN } from "../../utils";
import useMobileScreen from "../../hooks/useMobileScreen";
import ProjectCard from "./ProjectCard.component";
import { cardProjectData } from "../../store/storeAllData";

const Projects = () => {
  const isMobile = useMobileScreen(MOBILE_WIDTH_SCREEN);

  return (
    <Wrapper id="projects">
      <Container>
        <TextWithTitle
          title={descriptionsData[0].title}
          linkTo={"/projects"}
          buttonText={"See all projects"}
          customStyles={{
            container: {
              display: "flex",
              justifyContent: "space-between",
            },
          }}
        />
        <ProjectsWrapper>
          {cardProjectData.slice(0, 2).map((item, i) => (
            <ProjectCard
              key={i}
              alt={item.alt}
              image={item.img}
              intro={item.content.intro}
              title={item.title}
              url={item.url}
              reverse={i % 2 === 1}
              stack={item.stack}
            />
          ))}
        </ProjectsWrapper>
        {isMobile && (
          <Button>
            <Link to={"/projects"}>
              See all projects <Icon src={arrowSVG} alt="arrow-icon" />
            </Link>
          </Button>
        )}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background-color: #eef2f9;
  display: flex;
  justify-content: center;
  padding-bottom: 5rem;
  @media screen and (max-width: 1024px) {
    padding-bottom: 1.7rem;
  }
`;

const Container = styled.div`
  width: 83%;
  margin-top: 5rem;
`;

const ProjectsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 3rem;
  row-gap: 3rem;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.375rem;
  z-index: 12;

  background: linear-gradient(
    86.33deg,
    #1244c5 0.53%,
    #225aeb 50.08%,
    #2490f4 96.62%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  @media screen and (max-width: 1024px) {
    margin-top: 3rem;
  }
  @media screen and (min-width: 1920px) {
    font-size: calc(20px + (20 - 16) * ((100vw - 1440px) / (1920 - 1440)));
  }
`;

const Icon = styled.img``;

export default Projects;

const descriptionsData = [
  {
    title: "<Selected projects>",
    description:
      "We offer services in product development, software solutions, outsourcing, extended teams, and not only.",
  },
];
