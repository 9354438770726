import React from "react";
import styled from "@emotion/styled";

import starIcon from "../../assets/images/icons/star-icon.svg";
import rocketIcon from "../../assets/images/icons/rocket-icon.svg";

const data = [
  {
    img: starIcon,
    alt: "star-icon",
    title: "Misson",
    description:
      "We help businesses bring their ideas into successful software solutions.",
  },
  {
    img: rocketIcon,
    title: "Vision",
    alt: "rocket-icon",
    description:
      "Every line of code, is something we proudly stands behind and this is possible only thanks to our biggest asset - The Team.",
  },
];

interface CardProps {
  img: string;
  alt: string;
  title: string;
  description: string;
}

const Card = ({ img, alt, title, description }: CardProps) => (
  <CardContainer>
    <Icon
      src={img}
      alt={alt}
      title="content-icon"
      width="auto"
      height="auto"
      loading="eager"
    />
    <Title>{title}</Title>
    <Description>{description}</Description>
  </CardContainer>
);

const Worldview = () => {
  return (
    <Wrapper>
      {data.map((card: CardProps, i: number) => (
        <Card
          key={i}
          img={card.img}
          alt={card.alt}
          title={card.title}
          description={card.description}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
  margin-bottom: 2.375rem;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 50px;
  }

  /* @media screen and (min-width: 1024px) and (max-width: 1300px) {
    flex-direction: column;
    gap: 3rem;
  } */
`;
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 1rem;
  width: 45%;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;
const Icon = styled.img`
  width: 38px;
`;
const Title = styled.h2`
  font-family: "Outfit";
  color: #1c232c;
  font-size: calc(46px + (46 - 34) * ((100vw - 1024px) / (1920 - 1024)));
`;
const Description = styled.div`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  line-height: 1.75;
  color: #445062;
  font-size: calc(18px + (18 - 16) * ((100vw - 1024px) / (1920 - 1024)));
`;

export default Worldview;
