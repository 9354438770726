import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { DoSection } from "../components/proposal/ProposalSection.component";
import ProjectComponent from "../components/projects/simple-project-layout/ProjectComponent.component";
import TextWithTitle from "../components/layout-elements/TextWithTitle.component";

import reactImage from "../assets/images/services/react.svg";
import manageTeamImage from "../assets/images/services/manage-team.svg";
import extendedTeamImage from "../assets/images/services/extended-team.svg";
import { MOBILE_WIDTH_SCREEN } from "../utils";
import useMobileScreen from "../hooks/useMobileScreen";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const serviceCardData = [
  {
    image: reactImage,
    alt: "services-react-image",
    title: "<Product development>",
    id: "product-development",
    description:
      "We take care of the process, you enjoy the results. We take control of all processes to deliver the best results on time, on scope and on budget. From requirements management and project planning to quality assurance, deployment and product launch. Our clients rely on our project management expertise and strong technological capabilities in the short, medium and long term.",
  },
  {
    image: manageTeamImage,
    alt: "services-manage-team-image",
    title: "<Managed Team>",
    id: "managed-team",
    description:
      "Build complete team ready to work on your projects. We may build complete team, ready to work on your projects, from project management to delivery and testing. With this model we assign a dedicated team and the respective roles that correspond to the project’s requirements. We merge with your company and align with your culture to deliver the best work.",
  },
  {
    image: extendedTeamImage,
    alt: "services-extended-team-image",
    title: "<Extended Team>",
    id: "extended-team",
    description:
      "Extra pair of hands for your in-house team. We have successfully set up extended teams for our clients. We know how to make the extended model work for you and to help you navigate the most common pitfalls. You can be sure that the members of the extended team are just as invested in the process of building your product as your in-house team.",
  },
];

const customTitleStyle = {
  title: {
    fontSize: "60px",
    lineHeight: "72px",
  },
  description: {
    fontSize: "24px",
    lineHeight: "34px",
    width: "94%",
  },
  container: {
    paddingTop: "60px",
    paddingBottom: "91px",
  },
};

interface CardProps {
  image: string;
  alt: string;
  title: string;
  description: string;
  id: string;
}

const ServiceCard = ({ image, alt, title, description, id }: CardProps) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const { hash } = useLocation();

  useEffect(() => {
    if (hash === `#${id}`) {
      sectionRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [id, hash]);

  return (
    <ServiceCardWrapper ref={sectionRef} id={id}>
      <ServiceImage
        src={image}
        alt={alt}
        title={alt}
        width="auto"
        height="auto"
        loading="eager"
      />
      <ServiceTextContainer>
        <ServiceTitle>{title}</ServiceTitle>
        <ServiceDescription>{description}</ServiceDescription>
      </ServiceTextContainer>
    </ServiceCardWrapper>
  );
};

const ServicesPage = () => {
  const isMobile = useMobileScreen(MOBILE_WIDTH_SCREEN);

  return (
    <Wrapper>
      <Helmet>
        <title>What we offer</title>
        <meta
          name="description"
          content="We offer services in product development, software solutions, outsourcing, extended teams, and not only."
        />
        <link rel="canonical" href="https://www.kodinsoft.net/services" />
      </Helmet>
      <FirstContainer>
        <TextWithTitle
          title="What we offer"
          description="We offer services in product development, software solutions, outsourcing, extended teams, and not only. We are super flexible and always open of finding the most effective ways we can work together to bring your idea to life."
          showButton={false}
          customStyles={customTitleStyle}
          type="h1"
        />
        {serviceCardData.map((card, i) => (
          <ServiceCard
            key={i}
            image={card.image}
            alt={card.alt}
            title={card.title}
            description={card.description}
            id={card.id}
          />
        ))}
      </FirstContainer>
      <SecondContainer>
        <DoSection isMobile={isMobile} customStyles={{ width: "90%" }} />
        <ProjectComponent />
      </SecondContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 3rem;
  background: #eef2f9;
`;
const FirstContainer = styled.div`
  width: 70%;
  margin: auto;
  @media screen and (max-width: 1024px) {
    width: 89%;
  }
`;
const SecondContainer = styled.div`
  background: linear-gradient(180deg, #f8f9f9 0%, #e9f1fc 100%);
  display: flex;
  flex-wrap: wrap;
  row-gap: 9rem;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    row-gap: 3rem;
  }
`;

const ServiceCardWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 24px 72px rgba(21, 24, 28, 0.15);
  background: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-bottom: 4.5rem;
  padding-top: 4.875rem;
  padding-bottom: 4.875rem;
  gap: 1rem;

  &:last-child {
    margin-bottom: 9.5rem;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 1rem;
  }

  @media screen and (min-width: 1024px) and (max-width: 1245px) {
    flex-direction: column;
    margin: auto;
    margin-bottom: 2rem;
    min-width: 28rem;
  }
`;
const ServiceImage = styled.img`
  width: 20rem;
  height: 20rem;

  @media screen and (max-width: 1024px) {
    max-width: 18.5rem;
  }
`;
const ServiceTextContainer = styled.div``;
const ServiceTitle = styled.h2`
  font-family: "Outfit";
  font-size: 2.25rem;
  line-height: 3rem;
  color: #1c232c;
  @media screen and (min-width: 1700px) {
    font-size: calc(36px + (36 - 12) * ((100vw - 768px) / (2560 - 768)));
  }
`;
const ServiceDescription = styled.p`
  font-family: "Outfit";
  /* font-size: 1.25rem; */
  font-size: calc(18px + (18 - 14) * ((100vw - 1024px) / (1920 - 1024)));
  line-height: 1.75rem;
  color: #6d819d;
  @media screen and (min-width: 1700px) {
    font-size: calc(20px + (20 - 12) * ((100vw - 768px) / (2560 - 768)));
  }
`;

export default ServicesPage;
