import React from "react";
import styled from "@emotion/styled";
import { MOBILE_WIDTH_SCREEN } from "../../utils";
import useMobileScreen from "../../hooks/useMobileScreen";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  description?: string;
  icon: string;
  style?: any;
  alt: string;
  link?: string;
}

const CardWithIcon = ({
  icon,
  title,
  description,
  style,
  alt,
  link,
}: Props) => {
  const isMobile = useMobileScreen(MOBILE_WIDTH_SCREEN);

  return (
    <>
      <Card
        style={{
          width: isMobile && description ? "100%" : style?.width,
          height: style?.height,
          maxHeight: style?.maxHeight,
          cursor: link ? "pointer" : "default",
        }}
        to={link ? link : "#"}
      >
        <Icon
          src={icon}
          alt={alt}
          style={{ width: style?.iconWidth }}
          width="auto"
          height="auto"
          title={alt}
          loading="eager"
        />
        <CardTitle
          style={{ fontSize: style?.fontSize, width: style?.titleWidth }}
        >
          {title}
        </CardTitle>
        {description && <CardDescription>{description}</CardDescription>}
      </Card>
    </>
  );
};

const Card = styled(Link)<any>`
  width: 23.5%;
  height: 12rem;
  padding: 1.75rem;
  margin-top: 3rem;
  background-color: rgba(248, 249, 249, 0.6);
  box-shadow: 0px 24px 72px rgba(21, 24, 28, 0.08);
  backdrop-filter: blur(12px);
  border-width: 3px;
  border-style: solid;
  transition: all 0.3s ease-in-out;
  --border-angle: 75deg;
  border-image: conic-gradient(
      from var(--border-angle),
      rgba(214, 222, 235, 1) 0%,
      rgba(248, 249, 249, 1) 25%,
      rgba(248, 249, 249, 1) 50%,
      rgba(214, 222, 235, 1) 75%
    )
    1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  text-decoration: none;
  @media screen and (max-width: 1024px) {
    width: 47%;
  }
  @media screen and (max-width: 530px) {
    width: 100%;
  }

  &:hover {
    transform: scale(1.1);
    animation: spin 3s linear infinite;
  }
  @keyframes spin {
    0% {
      --border-angle: 75deg;
    }
    5% {
      --border-angle: 93deg;
    }
    10% {
      --border-angle: 110deg;
    }
    15% {
      --border-angle: 128deg;
    }
    20% {
      --border-angle: 145deg;
    }
    25% {
      --border-angle: 163deg;
    }
    30% {
      --border-angle: 180deg;
    }
    35% {
      --border-angle: 198deg;
    }
    40% {
      --border-angle: 215deg;
    }
    45% {
      --border-angle: 233deg;
    }
    50% {
      --border-angle: 250deg;
    }
    55% {
      --border-angle: 268deg;
    }
    60% {
      --border-angle: 285deg;
    }
    65% {
      --border-angle: 303deg;
    }
    70% {
      --border-angle: 320deg;
    }
    75% {
      --border-angle: 338deg;
    }
    80% {
      --border-angle: 355deg;
    }
    85% {
      --border-angle: 13deg;
    }
    90% {
      --border-angle: 30deg;
    }
    95% {
      --border-angle: 55deg;
    }
    100% {
      --border-angle: 75deg;
    }
  }

  @property --border-angle {
    inherits: true;
    initial-value: 75deg;
  }
`;

const Icon = styled.img`
  padding: 6px 0px;
  @media screen and (min-width: 1024px) and (max-width: 1205px) {
    width: 6rem;
  }
`;
const CardTitle = styled.p`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: calc(24px + (24 - 18) * ((100vw - 1024px) / (1920 - 1024)));
  line-height: 2.125rem;
  color: #1c232c;
  width: 12rem;

  /* @media screen and (min-width: 1920px) {
    font-size: calc(24px + (24 - 18) * ((100vw - 1440px) / (1920 - 1440)));
  } */
`;
const CardDescription = styled.p`
  margin-top: 2rem;
  color: #6d819d;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
`;

export default CardWithIcon;
