import styled from "@emotion/styled";
import React, { useEffect } from "react";

const ClutchWidget = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://widget.clutch.co/static/js/widget.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Wrapper
      className="clutch-widget"
      data-url="https://widget.clutch.co"
      data-widget-type="2"
      data-height="90"
      data-nofollow="true"
      data-expandifr="true"
      data-scale="100"
      data-clutchcompany-id="2014983"
    />
  );
};

export default ClutchWidget;

const Wrapper = styled.div`
  width: 250px;
  height: 90px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  padding: 30px;

  border: 2px solid;

  border-image: linear-gradient(
      217deg,
      rgba(214, 222, 235, 1),
      rgba(248, 249, 249, 1)
    )
    1;
`;
