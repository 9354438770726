import { configureStore } from '@reduxjs/toolkit';
import servicesReducer from './store/slices/serviceSlice';

export const store = configureStore({
	reducer: { service: servicesReducer, },
});

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
