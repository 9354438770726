import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { testimonailData } from "../storeAllData";

const initialState = {
  expanded: "",
  testimonials: testimonailData,
};

export const servicesSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setIsServiceExpand: (state, action: PayloadAction<string>) => {
      state.expanded = action.payload;
      return state;
    },
  },
});

export const { setIsServiceExpand } = servicesSlice.actions;
export default servicesSlice.reducer;
