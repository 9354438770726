import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import arrowIcon from "../../assets/images/icons/scroll-to-top.svg";

export default function ScrollToTop() {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const { pathname } = useLocation();

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const shouldShow = scrollY > 450;
      setShowScrollToTop(shouldShow);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <ScrollToTopContainer onClick={handleScrollToTop} show={showScrollToTop}>
      <Image
        src={arrowIcon}
        alt="scroll-to-top-icon"
        loading="lazy"
        width="auto"
        height="auto"
        title="scroll-to-top-icon"
      />
      <Text>Scroll to top</Text>
    </ScrollToTopContainer>
  );
}

const ScrollToTopContainer = styled.div<{ show: boolean }>`
  position: fixed;
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  bottom: 2.5rem;
  right: 2rem;
  padding: 0.5rem;
  border-radius: 50%;
  z-index: 999;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  svg {
    color: #1c232c;
  }
`;

const Image = styled.img`
  width: 3rem;
`;

const Text = styled.p`
  color: #6d819d;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
  padding-top: 0.5rem;
`;
