import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import arrowSVG from "../../assets/images/arrow-right-new.svg";
import { MOBILE_WIDTH_SCREEN } from "../../utils";
import useMobileScreen from "../../hooks/useMobileScreen";

interface Props {
  title: string;
  description?: string;
  showButton?: boolean;
  linkTo?: string;
  customStyles?: any;
  buttonText?: string;
  type?: "h1" | "h2";
}

const TextWithTitle = ({
  title,
  description,
  showButton = true,
  customStyles,
  linkTo,
  buttonText,
  type = "h2",
}: Props) => {
  const isMobile = useMobileScreen(MOBILE_WIDTH_SCREEN);

  return (
    <div style={customStyles?.container}>
      <Title as={type} style={customStyles?.title}>
        {title}
      </Title>
      <SubWrapper>
        {description && (
          <Description style={customStyles?.description}>
            {description}
          </Description>
        )}
        {!isMobile && showButton && linkTo && (
          <Button>
            <Link to={linkTo}>
              {buttonText ? buttonText : "See more"}
              <Icon
                src={arrowSVG}
                alt="arrow-icon"
                width="auto"
                height="auto"
                title="arrow"
                loading="lazy"
              />
            </Link>
          </Button>
        )}
      </SubWrapper>
    </div>
  );
};
const Title = styled.h2`
  color: #1c232c;
  font-family: "Outfit";
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 3.75rem;

  @media screen and (max-width: 1024px) {
    font-size: calc(
      40px + (40 - 20) * ((100vw - 375px) / (1024 - 375))
    ) !important;
  }

  @media screen and (min-width: 1920px) {
    font-size: calc(
      60px + (60 - 48) * ((100vw - 1440px) / (1920 - 1440))
    ) !important;
  }
`;

const SubWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Description = styled.p`
  font-family: "Outfit";
  color: #445062;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.625rem;
  width: 80%;
  white-space: pre-line;

  @media screen and (max-width: 1024px) {
    font-size: 20px !important ;
    font-size: calc(
      20px + (20 - 16) * ((100vw - 375px) / (1024 - 375))
    ) !important;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  /* @media screen and (min-width: 1550px) {
    width: 75%;
  }

  @media screen and (min-width: 2450px) {
    width: 80%;
  } */

  @media screen and (min-width: 1920px) {
    font-size: calc(20px + (20 - 16) * ((100vw - 1440px) / (1920 - 1440)));
  }
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.375rem;
  z-index: 12;

  background: linear-gradient(
    86.33deg,
    #1244c5 0.53%,
    #225aeb 50.08%,
    #2490f4 96.62%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  @media screen and (min-width: 1920px) {
    font-size: calc(20px + (20 - 16) * ((100vw - 1440px) / (1920 - 1440)));
  }
`;

const Icon = styled.img``;

export default TextWithTitle;
