import React from "react";
import styled from "@emotion/styled";

interface Props {
  logo: string;
  company: string;
  website: string;
  alt: string;
}

const ClientCard = ({ logo, company, website, alt }: Props) => {
  return (
    <Wrapper>
      <a
        href={website}
        target="_blank"
        rel="noreferrer"
        style={{
          textDecoration: "none",
        }}
      >
        <Logo
          src={logo}
          alt={alt}
          title="company-logo"
          width="auto"
          height="auto"
          loading="eager"
        />
        <TextContainer>
          <Company>{company}</Company>
        </TextContainer>
      </a>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  height: 7rem;
  /* width: 24%; */

  /* @media screen and (max-width: 500px) {
    align-items: center;
  } */
`;
const Logo = styled.img``;
const TextContainer = styled.div``;
const Company = styled.h2`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #1c232c;

  @media screen and (min-width: 1550px) {
    font-size: calc(20px + (20 - 16) * ((100vw - 768px) / (2560 - 768)));
  }
`;

export default ClientCard;
