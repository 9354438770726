import { useEffect, useState } from "react";

const useMobileScreen = (mobileWidthScreen: number): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(
    window.innerWidth < mobileWidthScreen
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < mobileWidthScreen);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mobileWidthScreen]);

  return isMobile;
};

export default useMobileScreen;
