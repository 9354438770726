import styled from "@emotion/styled";

const BULET_SQUARE = require("../../assets/images/projects/bulet.svg");

export const Title = styled.h1`
  color: #1c232c;
  font-size: calc(30px + (60 - 30) * ((100vw - 375px) / (1920 - 375)));
  font-family: "Outfit";
`;

export const Subtitle = styled.h2`
  color: #1c232c;
  font-size: calc(30px + (48 - 30) * ((100vw - 375px) / (1920 - 375)));
  font-family: "Outfit";
`;

export const BulletTitle = styled.h2`
  color: #1c232c;
  font-size: calc(24px + (48 - 24) * ((100vw - 375px) / (1920 - 375)));
  font-family: "Outfit";
  ::before {
    content: "";
    position: absolute;
    transform: translate(-230%, 80%);
    width: 24px;
    height: 24px;
    background-image: url(${BULET_SQUARE.default});
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

export const Paragraph = styled.p`
  color: #445062;
  font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));
  font-family: "Outfit";
`;

export const DashedContainer = styled.div`
  border-left: 1px dashed blue;
  padding-left: 43px;
  margin-top: -6px;
  padding-bottom: 20px;
  &:first-of-type {
    ::before {
      content: "";
      position: absolute;
      transform: translate(-270%, -10%);
      width: 20px;
      height: 21px;
      background-color: white;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &:nth-last-of-type(2) {
    padding-bottom: 35px;
  }
`;
