import * as React from "react";

interface Props {
  color?: string;
  fill?: string;
}

const ArrowSvg = ({ color, fill }: Props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.08678 1.41342L8.92264 1.41309L15.0026 7.99969L8.92264 14.5863H6.08678L11.205 9.04158H0.998047V6.95779H11.205L6.08678 1.41342Z"
      fill={fill ? fill : "url(#paint0_linear_1332_697)"}
    />
    <defs>
      <linearGradient
        id="paint0_linear_1332_697"
        x1="0.998047"
        y1="13.4167"
        x2="15.2504"
        y2="12.4457"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="1" stopColor={color ? color : ("#2490F4" as any)} />
      </linearGradient>
    </defs>
  </svg>
);

export default ArrowSvg;
