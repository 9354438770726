import reviewImg from '../assets/images/review-image.jpg';
import webAppBigIcon from '../assets/images/expertise/web-app.svg';
import mobileAppBigIcon from '../assets/images/expertise/mobile-app.svg';
import cloudBigIcon from '../assets/images/expertise/cloud.svg';
import backEndBigIcon from '../assets/images/expertise/back-end.svg';

import webappIcon from '../assets/images/icons/web-app.svg';
import mobileAppIcon from '../assets/images/icons/mobile-app.svg';
import backEndIcon from '../assets/images/icons/back-end.svg';
import cloudIcon from '../assets/images/icons/cloud.svg';
import telescopeLiveImage from '../assets/images/projects/telescope-live.jpg';
import spacefluxImage from '../assets/images/projects/spaceflux.png';

import pppImage from '../assets/images/projects/ppp.png';
import pppIntroImage from '../assets/images/projects/pppIntroImage.png';
import pppMailDocumentsImage from '../assets/images/projects/pppMailDocuments.png';
import pppPrintButtonImage from '../assets/images/projects/pppPrintButton.jpg';
import pppStatementPrintingImage from '../assets/images/projects/pppStatementPrinting.png';

import homePortImage from '../assets/images/projects/home-port-earth.jpg';
import homePortCoverImage from '../assets/images/projects/home-port.png';
import homePortDiagramImage from '../assets/images/projects/home-port-diagram.png';
import homePortImageBg from '../assets/images/projects/home-port-image.png';
import homePortImageBg1 from '../assets/images/projects/home-port-image-1.png';

import telescopeLiveCoverImage from '../assets/images/projects/telescope-live-hero.png';
import telescopeLiveAboutImage from '../assets/images/projects/telescope-live-about.jpg';
import telescopeLiveCollaborativeImage from '../assets/images/projects/telescpoe-live-collaborative.jpg';
import telescopeLiveCover from '../assets/images/projects/telescope-live-additional.jpg';

import spaceFluxCoverImage from '../assets/images/projects/spaceflux-cover.jpg';
import teamPhoto from '../assets/images/projects/team-photo.jpg';
import spacefluxCover from '../assets/images/projects/spaceflux-project-cover.jpg';
import progectOverviewImage from '../assets/images/projects/project-overview.png';

import greenBoxImage from '../assets/images/projects/green-box.jpg';
import greenBoxCoverImage from '../assets/images/projects/collecting.jpg';
import greenBoxOverviewImage from '../assets/images/projects/green-box-overview.jpg';
import greenbnoxBackgroundImage from '../assets/images/projects/greenbox.png';
import greenBoxImage1 from '../assets/images/projects/green-box1.jpg';

import reactIcon from '../assets/images/tech-stack/reactjs.svg';
import nextIcon from '../assets/images/tech-stack/nextjs.svg';
import pythonIcon from '../assets/images/tech-stack/python.svg';
import typeScriptIcon from '../assets/images/tech-stack/typescript.svg';
import azureIzon from '../assets/images/tech-stack/azure.svg';
import cSharpIcon from '../assets/images/tech-stack/c-sharp.svg';
import dockerIcon from '../assets/images/tech-stack/docker-icon.svg';
import rabbitMQIcon from '../assets/images/tech-stack/rabbit-mq.svg';
import azureContainerIcon from '../assets/images/tech-stack/azure-container-app.svg';
import azureIoTIcon from '../assets/images/tech-stack/azure-iot-hub.svg';
import azureServiceBusIcon from '../assets/images/tech-stack/azure-service-bus.svg';
import azureStorageIcon from '../assets/images/tech-stack/azure-storage.svg';
import azureSQLIcon from '../assets/images/tech-stack/azure-sql.svg';
import angularIcon from '../assets/images/tech-stack/angular.svg';
import dotNetIcon from '../assets/images/tech-stack/dot-net.svg';
import dotNetCoreIcon from '../assets/images/tech-stack/dont-net-core.svg';
import googleMapsIcon from '../assets/images/tech-stack/google-maps.svg';
import xamarinIcon from '../assets/images/tech-stack/xamarin.svg';
import azureWebAppsIcon from '../assets/images/tech-stack/azure-web-apps.svg';
import awsIcon from '../assets/images/tech-stack/aws.svg';
import nodeIcon from '../assets/images/tech-stack/nodejs.svg';
import openAPIGeneratorIcon from '../assets/images/tech-stack/open-api.svg';
import awsCognitoIcon from '../assets/images/tech-stack/aws-cognito.svg';
import awsS3Icon from '../assets/images/tech-stack/aws-s3.svg';
import awsLambdaIcon from '../assets/images/tech-stack/aws-lambda.svg';
import awsCloudFormationIcon from '../assets/images/tech-stack/aws-cloud-formation.svg';
import awsApiGatewayIcon from '../assets/images/tech-stack/aws-api-gateway.svg';
import awsRoute53Icon from '../assets/images/tech-stack/aws-route-53.svg';
import awsStepFunctionsIcon from '../assets/images/tech-stack/aws-step-functions.svg';
import awsCloudWatchIcon from '../assets/images/tech-stack/aws-cloud-watch.svg';
import awsAmplifyIcon from '../assets/images/tech-stack/aws-amplify.svg';
import awsAppSyncIcon from '../assets/images/tech-stack/aws-app-sync.svg';
import awsDynamoDBIcon from '../assets/images/tech-stack/aws-dynamo-db.svg';
import vueJsIcon from '../assets/images/tech-stack/vuejs.svg';
import javaIcon from '../assets/images/tech-stack/java.svg';

export const testimonailData = [
	{
		review: "It has been a pleasure directly working with the team of Kodin Soft on our telescope live project. Glad that we've chosen you to help us out. I appreciated your professionalism and creative approach to every aspect and challenge that came up our way.",
		name: 'David Mahitsy',
		company: 'CEO at SomeecOll COmpany',
		img: reviewImg,
	},
	{
		review: 'The team at Kodin Soft is professional and knowledgeable. They completed our project on time and exceeded our expectations. We will definitely work with them again.',
		name: 'John Doe',
		company: 'CEO at Another Company',
		img: reviewImg,
	},
	{
		review: 'Working with Kodin Soft was a great experience. They were very responsive and solved any issues that arose quickly. We highly recommend them.',
		name: 'Bob Smith',
		company: 'CTO at Yet Yet Another Company',
		img: reviewImg,
	},
	{
		review: 'Kodin Soft provided excellent service and delivered a high-quality product. Their attention to detail and commitment to customer satisfaction is unmatched.',
		name: 'Jane Doe',
		company: 'CMO at Yet Another Company',
		img: reviewImg,
	},
	{
		review: "It has been a pleasure directly working with the team of Kodin Soft on our telescope live project. Glad that we've chosen you to help us out. I appreciated your professionalism and creative approach to every aspect and challenge that came up our way.",
		name: 'David Mahitsy',
		company: 'CEO at SomeecOll COmpany',
		img: reviewImg,
	},
	{
		review: 'The team at Kodin Soft is professional and knowledgeable. They completed our project on time and exceeded our expectations. We will definitely work with them again.',
		name: 'John Doe',
		company: 'CEO at Another Company',
		img: reviewImg,
	},
	{
		review: 'Working with Kodin Soft was a great experience. They were very responsive and solved any issues that arose quickly. We highly recommend them.',
		name: 'Bob Smith',
		company: 'CTO at Yet Yet Another Company',
		img: reviewImg,
	},
	{
		review: 'Kodin Soft provided excellent service and delivered a high-quality product. Their attention to detail and commitment to customer satisfaction is unmatched.',
		name: 'Jane Doe',
		company: 'CMO at Yet Another Company',
		img: reviewImg,
	},
];

export const expertiseCardData = {
	web: {
		image: webAppBigIcon,
		alt: 'web-development-icon',
		title: '<Web development>',
		description:
			'Custom software development, in essence, is translating your users’ needs and your product’s functionalities into something a computer will understand. It entails designing, building, deploying, and maintaining software specific to your designated group of users, organizations, and functions. Our team of software experts specialize in web, mobile, backend, database and cloud technologies is ready to build your ideas.',
		content: [
			{
				title: 'Web app',
				description:
					'Take advantage of a robust web application to improve your customers’ experience and attract more prospects. Whatever features you have in mind, our skilled full-stack developers will use the best frameworks to deliver you a competitive edge with intuitive web design and a great user experience. Hesitating on what you need? Our expert Tech Leads will help you pick the right solution.',
				techStack: [
					{
						icon: nextIcon,
						name: 'Next.js',
						alt: 'next-icon',
					},
					{
						icon: angularIcon,
						name: 'Angular',
						alt: 'angular-icon',
					},
					{
						icon: reactIcon,
						name: 'React',
						alt: 'react-icon',
					},
					{
						icon: typeScriptIcon,
						name: 'TypeScript',
						alt: 'type-script-icon',
					},
				],
			},
			{
				title: 'Progressive web app',
				description:
					'Deliver exquisite digital experiences to your customers by creating a website that looks and feels like a mobile app. Our custom web application developers will build a sophisticated hybrid app, aka Progressive Web App (PWA), enabling advanced features, such as push notifications, offline mode, geo-locations, and finer indexability, to name a few.',
				techStack: [],
			},
		],
	},
	mobile: {
		image: mobileAppBigIcon,
		alt: 'mobile-development-icon',
		title: '<Mobile development>',
		description:
			'Mobile app solutions for various platforms. Leverage our expertise in multi-platform mobile app development to fascinate your customers regardless of the type of mobile device they use.',
		content: [
			{
				title: 'Cross-platform app development',
				description:
					'Cross-platform application is a technology that allows you to use code across iOS, Android, and web platforms. It helps to optimize the budget and timeline for development, as well as simplify the following support. Our team will turn your ideas into high-tech solutions and design mobile apps using a user-centered approach. When creating mobile applications, we adhere to modern product development practices and follow the global world’s best cases. Using our experience we are able to choose the most appropriate technologies for your project. An efficient way to build robust and stable applications is by using universal cross-platform frameworks such as React Native or Flutter.',
			},
		],
	},
	backend: {
		image: backEndBigIcon,
		alt: 'cloud-icon',
		title: '<Backend development>',
		description:
			"We understand the role of an efficient and feature-rich backend for a successful web or mobile application - that's the reason the backend we develop are not only high performing but also caters features that give ease of application management. Our backend developers will build custom APIs, develop your business logic and integrate monitoring & analytics tools for understanding business and KPIs. Cloud operations as building DevOps CD/CI are prone to fail easily if handled by teams with no prior experience. Avoid multi-figure mistakes leveraging with our DevOps experts with a proven record in cloud-native delivery.",
		techStack: [
			{
				icon: nextIcon,
				name: 'Next.js',
				alt: 'next-icon',
			},
			{
				icon: angularIcon,
				name: 'Angular',
				alt: 'angular-icon',
			},
			{
				icon: reactIcon,
				name: 'React',
				alt: 'react-icon',
			},
			{
				icon: typeScriptIcon,
				name: 'TypeScript',
				alt: 'type-script-icon',
			},
		],
		content: [],
	},
	cloud: {
		image: cloudBigIcon,
		alt: 'cloud-icon',
		title: '<Cloud native development>',
		description:
			'Cloud-native application development is an approach to building and running applications that fully embrace the advantages of cloud computing. A cloud-native application is designed to run on cloud infrastructure, utilizing cloud services and technologies, and follows a set of principles and practices that enable rapid development, deployment, and scaling.',
		content: [
			{
				title: '',
				description: `Cloud-native application development involves breaking down large, monolithic applications into smaller, independent services, called microservices. Each microservice is designed to be highly available, fault-tolerant, and scalable, and can be developed, deployed, and managed independently.

          To achieve these goals, cloud-native applications are typically built using containerization technologies, such as Docker, and orchestrated using container orchestration platforms, such as Kubernetes. They are also designed to be horizontally scalable, meaning that additional instances of the application can be added or removed automatically in response to changes in demand.

          Cloud-native application development also involves leveraging cloud services, such as serverless computing, databases, storage, and messaging, to reduce the complexity and cost of building and running applications. This enables developers to focus on building the core features of the application, rather than worrying about the underlying infrastructure. Our development teams are well-versed with microservice and serverless kind of software architecture services.

          Overall, cloud-native application development is a modern approach to building applications that leverages the benefits of cloud computing to improve agility, scalability, and cost-efficiency.

          A well-designed software architecture saves thousands of development hours. Make your software architecture adapt to changing business goals. Our software architects will help you establish a secure, flexible, and efficient software development process that meets your needs.`,
			},
		],
		techStack: [
			{
				icon: azureStorageIcon,
				name: 'Storage',
				alt: 'azure-storage-icon',
			},
			{
				icon: azureSQLIcon,
				name: 'SQL',
				alt: 'sql-icon',
			},
			{
				icon: azureServiceBusIcon,
				name: 'Service Bus',
				alt: 'service-bus-icon',
			},
		],
	},
};

export const cardsData = [
	{
		icon: webappIcon,
		title: 'Web development',
		alt: 'web',
		link: '/expertise/web-development',
	},
	{
		icon: mobileAppIcon,
		title: 'Mobile development',
		alt: 'mobile',
		link: '/expertise/mobile-development',
	},
	{
		icon: backEndIcon,
		title: 'Backend development',
		alt: 'back-end',
		link: '/expertise/backend-development',
	},
	{
		icon: cloudIcon,
		title: 'Cloud native development',
		alt: 'cloud',
		link: '/expertise/cloud-native-development',
	},
];

export const cardProjectData = [
	{
		title: '<TelescopeLive>',
		text: 'TelescopeLive',
		img: telescopeLiveImage,
		alt: 'telescope-live',
		url: 'telescope-live',
		content: {
			intro: 'TELESCOPELIVE:ILLUMINATING THE NIGHT SKY \n Unveiling the Universe: Empowering Astrophotography and Remote Imaging \n A platform that empowers everyone, from astrophotography enthusiasts to space enthusiasts, to experience the wonders of the night sky like never before. Unlock the secrets of remote imaging and astrophotography, making it accessible to all.',
		},
		stack: [
			{
				icon: angularIcon,
				name: 'Angular',
				alt: 'angular-icon',
			},
			{
				icon: azureIzon,
				name: 'Azure',
				alt: 'azure-icon',
			},
			{
				icon: cSharpIcon,
				name: 'C#',
				alt: 'csharp-icon',
			},
			{
				icon: pythonIcon,
				name: 'Python',
				alt: 'python-icon',
			},
		],
	},
	{
		title: '<SpaceFlux>',
		text: 'SpaceFlux',
		img: spacefluxImage,
		alt: 'spaceflux',
		url: 'spaceflux',
		content: {
			intro: 'SPACEFLUX - DISTRIBUTED CLOUD-EDGE PLATFORM \n Revolutionizing Space Tracking: Unveiling a New Frontier \n Distributed platform that provides access to automated satellite and space debris tracking services. The platform leverages a proprietary network of taskable optical sensors, enabling us to offer comprehensive orbit determination, threat monitoring, and object characterization capabilities.',
		},
		stack: [
			{
				icon: nextIcon,
				name: 'Next.js',
				alt: 'next-icon',
			},
			{
				icon: azureIzon,
				name: 'Azure',
				alt: 'azure-icon',
			},
			{
				icon: cSharpIcon,
				name: 'C#',
				alt: 'csharp-icon',
			},
			{
				icon: pythonIcon,
				name: 'Python',
				alt: 'python-icon',
			},
		],
	},
	{
		title: '<Green Box>',
		text: 'Green Box',
		img: greenBoxImage,
		alt: 'greenbox',
		url: 'greenbox',
		content: {
			intro: 'GREEN BOX - AUTOMATING LOGISTICS CLOUD PLATFORM \n Revolutionizing Logistics: Streamline Collection Processes with Automation \n Introducing a cutting-edge cloud platform that transforms goods collection. Harnessing automation eliminates manual tasks, revolutionizing logistics. Say goodbye to inefficiencies, hello to optimized operations with Green Box.',
		},
		stack: [
			{
				icon: cSharpIcon,
				name: 'C#',
				alt: 'csharp-icon',
			},
			{
				icon: angularIcon,
				name: 'Angular',
				alt: 'angular-icon',
			},
			{
				icon: dotNetIcon,
				name: '.Net',
				alt: 'dotnet-icon',
			},
			{
				icon: azureIzon,
				name: 'Azure',
				alt: 'azure-icon',
			},
		],
	},
	{
		title: '<Campaign Printing Programmatic Platform>',
		text: 'Campaign Printing Programmatic Platform',
		img: pppImage,
		alt: 'PPP',
		url: 'ppp',
		content: {
			intro: 'Campaign Printing Programmatic Platform: Revolutionizing Direct Mail Marketing with the Campaign’s Programmatic Print Platform. In the rapidly evolving world of marketing, the integration of traditional and digital strategies is crucial for creating comprehensive campaigns that engage customers across multiple touchpoints. We are dedicated to developing a pioneering solution with innovative Programmatic Print technology, seamlessly blending the tangibility of direct mail with the precision and flexibility of digital marketing.',
		},
		stack: [
			{
				icon: nextIcon,
				name: 'Next.js',
				alt: 'next-icon',
			},
			{
				icon: awsIcon,
				name: 'AWS',
				alt: 'aws-icon',
			},
			{
				icon: nodeIcon,
				name: 'Node.js',
				alt: 'node-icon',
			},
			{
				icon: typeScriptIcon,
				name: 'Typescript',
				alt: 'typescript-icon',
			},
		],
	}, {
		title: '<HomePort>',
		text: 'Revolutionizing Earth Observation Data Access with Cloud-Based Satellite Integration',
		img: homePortImage,
		alt: 'homePort',
		url: 'homePort',
		content: {
			intro: 'HomePort is a groundbreaking platform designed to democratize Earth observation data access, empowering organizations across sectors to harness satellite imagery and insights without the prohibitive costs and complexity historically associated with such data. By integrating with satellite data providers through a cloud-based architecture, HomePort enables users to schedule satellite passes, manage data securely, and seamlessly access up-to-date, targeted imagery. This approach offers a scalable, secure solution for organizations of all sizes, removing traditional barriers to Earth data access and enabling a diverse range of applications, from environmental monitoring to commercial mission planning.',
		},
		stack: [
			{
				icon: vueJsIcon,
				name: 'Vue.js',
				alt: 'vue-icon',
			},
			{
				icon: javaIcon,
				name: 'Java',
				alt: 'java-icon',
			},
			{
				icon: awsIcon,
				name: 'AWS',
				alt: 'aws-icon',
			},
			{
				icon: awsLambdaIcon,
				name: 'Lambda',
				alt: 'aws-lambda-icon',
			},

		],
	},
];

export const projectsData = [
	{
		project: 'Telescope Live: Illuminating the Night Sky',
		title: 'Unveiling the Universe: Empowering Astrophotography and Remote Imaging',
		intro: 'At Kodin Soft, we embark on a mission to reveal the marvels of the universe through cutting-edge technology. Our passion lies in creating a platform that empowers everyone, from astrophotography enthusiasts to space enthusiasts, to experience the wonders of the night sky like never before. With creating such kind of solution, we have unlocked the secrets of remote imaging and astrophotography, making it accessible to all.',
		img: telescopeLiveCoverImage,
		alt: 'spaceflux-cover-image',
		url: 'telescope-live',
		content: {
			client: {
				img: telescopeLiveAboutImage,
				alt: 'project-image',
				title: 'About the Client',
				content: [
					'TelescopeLive is a UK-based company founded by a team of passionate scientists, engineers, and AI specialists. Their mission is to make astrophotography accessible to all, bringing the wonders of space within reach of regular individuals. Leveraging remote imaging technology and cutting-edge image-processing algorithms, TelescopeLive offers a unique platform that grants users access to a global network of telescopes, capturing the most vibrant images of space in real-time. Their state-of-the-art optical sensor network, fully automated with machine learning techniques, ensures the most reliable and high-fidelity data, creating an unparalleled astrophotography experience. ',
				],
			},
			success: {
				title: 'Pioneering Astrophotography: A Collaborative Journey with TelescopeLive',
				img: telescopeLiveCollaborativeImage,
				alt: 'team-photo',
				content: [
					"TelescopeLive's pursuit of a revolutionary platform that democratizes astrophotography led to a collaboration with Kodin Soft in 2020. As a software development company with extensive expertise in cloud-integrated solutions, Kodin Soft was the ideal partner to bring TelescopeLive's vision to life. Together, we embarked on a journey to develop an efficient, scalable, and user-friendly cloud-edge platform that seamlessly integrates AI-driven algorithms and TelescopeLive's proprietary optical sensors. With a dedicated team comprising a Product Owner, Software Architect, Front-End, and Back-End Engineers, Kodin Soft ensured the successful realization of the project.",
				],
			},
			implementation: {
				title: 'Implementation:',
				subtitle: 'Unveiling the Journey: Implementing an Innovative Platform',
				intro: [
					'At the heart of the TelescopeLive platform lies an intelligent microservices architecture, meticulously designed by Kodin Soft to ensure scalability, maintainability, and efficient testing. Embracing the power of Azure Cloud and robust AI tools, the software solution achieved fast operations and zero downtime. The seamless integration of advanced image-processing algorithms empowered users to process vast amounts of data and images from the global sensor network. The microservice architecture enabled uninterrupted user traffic, ensuring a seamless experience for astrophotography enthusiasts. Elevating IoT services further connected the telescope network to the web platform, synchronizing operations flawlessly.',
				],
				sections: [
					{
						title: 'Client First: Customizing Services for Evolving Requirements ',
						content:
							"Working closely with TelescopeLive, Kodin Soft identified and understood various business processes and workflows to tailor the platform's functionalities precisely to users' requirements. The collaborative effort ensured that the web interface handled various queries with ease and provided a personalized experience with optimal response times. This client-centric approach laid the foundation for a platform that aligns seamlessly with TelescopeLive's vision of making astrophotography accessible to all. ",
					},
					{
						title: 'Navigating the Cloud Galaxy: Optimizing Performance and Efficiency',
						content:
							'To build an efficient and scalable distributed system, Kodin Soft adopted a cloud-native development approach, maximizing the advantages of cloud computing. Azure Cloud services, such as Azure Apps, Azure Storage, Azure Functions, Azure SQL and Azure Service Bus, formed the robust foundation of the platform. Leveraging message queues, the architecture ensured secure transport of sensitive information within the microservice ecosystem, promoting scalability and decoupled communication. This cloud-centric architecture eliminated the need for managing and provisioning servers and dynamically scaled based on real-time demand. ',
					},
					{
						title: 'Enhancing User Experience: Designing an Intuitive and Resilient API',
						content:
							"TelescopeLive's specific requirements demanded a careful selection of appropriate front-end and back-end technologies. Kodin Soft chose Angular for the front-end, leveraging experience with similar-scale projects and development complexity. On the back-end, C#/.NET Core provided the foundation for robust operations, while Python distributed and scheduled custom development modules. This tech stack ensured an intuitive user interface and seamless processing of data. ",
					},
					{
						title: 'Empowering Scalability and Security: Building a Resilient Cloud Infrastructure ',
						content:
							"The platform's cloud infrastructure, built on Azure Cloud services, guaranteed scalability, cost-effectiveness, and robust security. Azure Apps, Azure Storage, Azure Functions, and Azure SQL facilitated smooth operations and maintained data integrity. With automated scaling based on demand, the platform handled user traffic efficiently without compromising security or performance. ",
					},
					{
						title: 'Continued Excellence: Ensuring Reliability, Performance Monitoring, and Ongoing Support',
						content:
							"TelescopeLive's journey with Kodin Soft didn't end with the initial implementation. To ensure optimal performance and identify potential issues, Kodin Soft utilized Azure tools like Application Insights for monitoring and troubleshooting. Comprehensive testing validated the platform's compliance with requirements and functionality. By continuously monitoring performance and user feedback, TelescopeLive and Kodin Soft work in tandem to optimize services, implement new features, and stay abreast of industry trends.",
					},
				],
			},
		},
		techStack: {
			backgroundColor: 'black',
			imageStyles: {
				backgroundImage: `url(${telescopeLiveCover})`,
				height: '600px',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
			},
			stack: [
				{
					icon: angularIcon,
					name: 'Angular',
					alt: 'angular-icon',
				},
				{
					icon: typeScriptIcon,
					name: 'TypeScript',
					alt: 'type-script-icon',
				},
				{
					icon: cSharpIcon,
					name: 'C#',
					alt: 'csharp-icon',
				},
				{
					icon: dotNetCoreIcon,
					name: '.Net Core',
					alt: 'dotnet-core-icon',
				},
				{
					icon: pythonIcon,
					name: 'Python',
					alt: 'python-icon',
				},
				{
					icon: dockerIcon,
					name: 'Docker',
					alt: 'docker-icon',
				},
			],
			azure: [
				{
					icon: azureStorageIcon,
					name: 'Storage',
					alt: 'azure-storage-icon',
				},
				{
					icon: azureSQLIcon,
					name: 'SQL',
					alt: 'sql-icon',
				},
				{
					icon: azureServiceBusIcon,
					name: 'Service Bus',
					alt: 'service-bus-icon',
				},
			],
		},
	},
	{
		project: 'SPACEFLUX - DISTRIBUTED CLOUD-EDGE PLATFORM',
		title: 'Revolutionizing Space Tracking: Unveiling a New Frontier',
		intro: 'We are dedicated to developing a cutting-edge distributed platform that provides access to automated satellite and space debris tracking services. The platform leverages a proprietary network of taskable optical sensors, enabling us to offer comprehensive orbit determination, threat monitoring, and object characterization capabilities.',
		img: spaceFluxCoverImage,
		alt: 'spaceflux-cover-image',
		url: 'spaceflux',
		content: {
			client: {
				img: progectOverviewImage,
				alt: 'project-image',
				title: 'About the Client',
				content: [
					'Spaceflux is a UK-based company, founded by a group of passionate scientists and engineers. The team consists of a powerhouse of scientific talent, with experts in telescope-based tracking, machine learning and AI, as well as experienced engineers well-versed in remote telescope operations. Spaceflux objective is to safeguard modern life by ensuring the safe operation of vulnerable space assets through AI-driven space situational awareness solutions, advanced optical sensors, and cutting-edge technology for satellite navigation in congested space.',
					"To guarantee the most reliable solutions, Spaceflux has a proprietary state-of-the-art network of optical sensors that provide high-fidelity and uncorrupted data in real time. The network includes the market's largest sensors, allowing the detection of the smallest and faintest objects from Low Earth Orbit (LEO) to Cislunar space. The operation of the sensor network is fully automated, leveraging machine learning techniques for maximum efficiency and reliability.",
				],
			},
			success: {
				title: 'Partnering for Success: Spaceflux and Kodin Soft Join Forces',
				img: teamPhoto,
				alt: 'team-photo',
				content: [
					'Spaceflux has partnered with Kodin Soft to develop a cutting-edge cloud-based service for tracking satellites and space debris. With their extensive expertise in cloud-integrated software solutions, Kodin Soft was the ideal choice for managing and implementing the software development aspects of the project, while Spaceflux focuses on their domain knowledge and state-of-the-art network of optical sensors and debris.',
					'Kodin Soft has assembled a dedicated team consisting of a Software Architect, Scrum Master, Frontend Engineers, and Backend Engineers to spearhead the project. This diverse team brings together their collective skills and experience to ensure the success of the collaboration.',
					"At the heart of the solution is an efficient and scalable cloud-edge platform, seamlessly integrating AI-driven algorithms and Spaceflux's exclusive network of cutting-edge optical sensors. The platform harnesses the power of advanced data analytics, utilizing machine learning algorithms to process vast amounts of data from the global sensor network. Built on a robust microservices architecture, the platform offers seamless scalability and rapid implementation of new services, tailored to meet the evolving feedback and requirements of Spaceflux's clients.",
					'By joining forces with Kodin Soft, Spaceflux can leverage their expertise in software development and cloud integration, enabling them to focus on their core competencies while delivering anBulletParagraphBulletParagraph exceptional tracking and monitoring service to their clients. The collaboration between Spaceflux and Kodin Soft ensures a comprehensive and innovative solution that meets the highest standards of performance and reliability in the space industry.',
				],
			},
			implementation: {
				title: 'Implementation:',
				sections: [
					{
						title: 'How We Brought It to Life',
						content:
							"Through extensive research and collaborative brainstorming, we developed a comprehensive plan to build a highly scalable and user-friendly distributed platform. With the platform's integration with a network of sensors, we anticipated a significant influx of data traffic, making scalability a top priority. To achieve this, we adopted a microservices architecture deployed using Docker containers hosted in Azure Container Apps. Azure IoT Hub was chosen for cloud-to-device messaging, while Azure SQL was selected for data persistence. The front-end web component was developed using Next.js provided by Vercel.",
					},
					{
						title: 'Client-Centric Approach: Tailoring Services to Evolving Needs',
						content:
							"We worked closely with the client to identify the various business processes and workflows that the platform would automate or support. Additionally, we carefully determined the requirements for the web interface, including the types of queries it should handle, the desired level of personalization, and the expected response time. This collaborative effort helped shape the design and development process by clearly defining the project's scope, goals, and objectives.",
					},
					{
						title: 'Navigating the Cloud Landscape: Selecting the Ideal Cloud Technologies for Optimal Performance and Efficiency',
						content:
							"To build efficient and scalable distributed systems, we recommended adopting a cloud-native development approach. This approach involves building and running applications that fully leverage the advantages of cloud computing. Cloud-native applications are designed to operate on cloud infrastructure, utilizing cloud services and technologies, while adhering to a set of principles and best practices that facilitate rapid development, deployment, and scaling. In line with these goals, we employed containerization technologies such as Docker and opted for Azure Container Apps, which provide a lightweight orchestration solution and are easy to set up. Furthermore, we leveraged Azure IoT Hub and Azure SQL for their respective cloud services' capabilities.",
					},
					{
						title: 'Crafting a Seamless User Experience and Robust API',
						content:
							"Given the project's specific requirements and use cases, selecting appropriate front-end and back-end technologies was crucial. After careful consideration, we chose Next.js for the front end, drawing on our past experiences with similar-scale projects and development complexity. For the back end, we selected C#/.NET Core. Additionally, we decided to use Python for distributing and scheduling custom development modules, as the Python ecosystem offered mature libraries with extensive support.",
					},
					{
						title: 'Building a Resilient Cloud Infrastructure for Scalability and Security',
						content:
							'Azure Cloud services, including Azure Container Apps, Azure Storage, Azure Functions, and Azure IoT Hub and Azure Service Bus formed the foundation of our scalable and cost-effective architecture. By leveraging message queues, we ensured secure transport of sensitive information within our microservice architecture, promoting decoupled communication and enhancing scalability. This cloud-based architecture eliminated the need for managing and provisioning servers and automatically scaled based on real-time demand.',
					},
					{
						title: 'Sustaining excellence: Ensuring Reliability, Monitoring Performance, and Providing Ongoing Support.',
						content:
							"To ensure optimal performance and identify any potential issues, we utilized Azure tools such as Application Insights for monitoring and troubleshooting. Thorough testing was conducted to ensure that the platform met all requirements and functioned correctly. Once deployed to production, we continuously monitored its performance to ensure it delivered the intended benefits. Furthermore, we invested in optimizing the integration based on user feedback and analytics data, further enhancing the platform's effectiveness.",
					},
				],
			},
		},
		techStack: {
			backgroundColor: 'black',
			backgroundImage: spacefluxCover,
			stack: [
				{
					icon: nextIcon,
					name: 'Next.js',
					alt: 'next-icon',
				},
				{
					icon: typeScriptIcon,
					name: 'TypeScript',
					alt: 'type-script-icon',
				},
				{
					icon: cSharpIcon,
					name: 'C#',
					alt: 'csharp-icon',
				},
				{
					icon: pythonIcon,
					name: 'Python',
					alt: 'python-icon',
				},
				{
					icon: dockerIcon,
					name: 'Docker',
					alt: 'docker-icon',
				},
				{
					icon: rabbitMQIcon,
					name: 'RabbitMQ',
					alt: 'rabbit-mq-icon',
				},
			],
			azure: [
				{
					icon: azureIoTIcon,
					name: 'IoT Hub',
					alt: 'azure-iot-icon',
				},
				{
					icon: azureContainerIcon,
					name: 'Container app',
					alt: 'azure-container-icon',
				},
				{
					icon: azureStorageIcon,
					name: 'Storage',
					alt: 'azure-storage-icon',
				},
				{
					icon: azureSQLIcon,
					name: 'SQL',
					alt: 'sql-icon',
				},
				{
					icon: azureServiceBusIcon,
					name: 'Service Bus',
					alt: 'service-bus-icon',
				},
			],
		},
	},
	{
		project: 'GREEN BOX - AUTOMATING LOGISTICS CLOUD PLATFORM',
		title: 'Revolutionizing Logistics: Streamline Collection Processes with Automation',
		intro: 'At Kodin Soft, we have earned a reputation as a reliable and trusted solution provider by spearheading the development and maintenance of a cutting-edge cloud platform and mobile application. Our innovative software enables the automation of logistics processes for efficient goods collection, seamless handling, and streamlined warehouse operations. By harnessing the power of automation, we empower our clients to save valuable time and significantly reduce operational costs. One of the most impactful advancements is the automated creation of route planning and goods collection, eliminating the need for time-consuming manual tasks traditionally performed by dispatchers. Our transformative solution revolutionizes the logistics landscape, paving the way for a more efficient and cost-effective future.',
		img: greenBoxCoverImage,
		alt: 'spaceflux-cover-image',
		url: 'greenbox',
		content: {
			client: {
				img: greenBoxOverviewImage,
				alt: 'some-alt',
				title: 'Unleashing Potential: Collaborating with Green Team to Transform FMGG Supply Chain ',
				content: [
					'Kodin Soft proudly collaborates with Green Team, a highly successful Bulgarian SMB company at the forefront of the FMGG sector. With an extensive retail chain comprising over 50 stores, Green Team operates in the USA, Canada, and Bulgaria. As a key player in the industry, their supply chain encompasses an impressive network of 703 collection points across the United States and Canada. These collection points seamlessly feed into five strategically located warehouses in New Jersey, Los Angeles, San Francisco, Seattle, and Vancouver. Supported by their well-developed in-house logistics service, Green Team has established itself as a leader in the industry. At Kodin Soft, we are excited to be a part of their journey, revolutionizing their FMGG supply chain with our innovative software solutions.',
				],
			},
			success: {
				img: greenBoxImage1,
				alt: 'some-alt',
				title: 'Efficient Execution: Implementing Innovative Solutions for Streamlined Logistics Operations  ',
				content: [
					'Back in 2014, Green Team faced the pressing challenge of optimizing their logistics processes to enhance efficiency, save valuable employee time, and reduce operational costs. A significant pain point for the company was the manual nature of routes planning and goods collection, heavily reliant on dispatcher analyses without the aid of computerized rules. ',
					'To address these challenges head-on, Kodin Soft introduced GreenBox, a comprehensive solution comprising a web route scheduling module and a powerful mobile application tailored specifically for collection teams. GreenBox seamlessly integrates essential logistics functionalities, transforming the way operations are conducted: ',
				],

				bulletsDesc: [
					'Goods Collection Routes Planning: GreenBox automates the process of planning optimal collection routes, ensuring efficient and cost-effective operations. It intelligently determines the best collection points to visit and suggests the most optimized routes, leveraging historical data and advanced algorithms. ',
					'Intelligent Time and Location Proposal: Leveraging a wealth of historical information and sophisticated analysis, GreenBox proposes the ideal time and location for pick-ups, enabling teams to maximize productivity while minimizing travel time and costs. ',
					'Collection Tracking and Reporting: GreenBox incorporates cutting-edge NFC technology and GPS integration, enabling real-time tracking and reporting of collections. This ensures transparency, accountability, and seamless coordination between collection teams and warehouses. ',
					'Streamlined Maintenance Tasks: GreenBox goes beyond logistics and offers maintenance task proposals and execution functionalities. This allows for efficient handling of maintenance tasks at collection locations, ensuring their optimal operational condition. ',
					'Comprehensive Financial and Management Reporting: GreenBox provides extensive reporting capabilities, offering valuable insights into financial performance and management metrics. By presenting clear and concise reports, it empowers decision-makers to make informed strategic choices.',
				],
				outro: 'Through the seamless integration of these powerful features, GreenBox empowers Green Team to revolutionize their logistics operations, leading to enhanced productivity, cost savings, and improved overall efficiency.  ',
			},
			implementation: {
				title: 'Tailored Solutions: A Client-Centric Approach to Address Evolving Needs',
				intro: [
					'At Kodin Soft, we understand the importance of aligning our services with the unique requirements of our clients. With the GreenBox project, we adopted a client-centric approach, working closely with Green Team to identify and address their specific business processes and workflows. This collaborative effort allowed us to determine the precise functionalities that the platform should automate or support, ensuring a tailored solution that truly meets their evolving needs. ',
					'During the course of almost a decade, we have continuously developed and refined the GreenBox platform, incorporating state-of-the-art technologies and industry best practices. The implementation leverages a powerful stack of technologies, including: ',
				],
				outro: 'Through the skillful integration of these cutting-edge technologies, GreenBox empowers Green Team with an innovative, feature-rich platform that optimizes logistics operations, enhances productivity, and drives tangible business outcomes. ',
				sections: [
					{
						title: '',
						content:
							'Microsoft Azure Cloud: By harnessing the capabilities of Azure, we have created a robust and scalable foundation for GreenBox. Utilizing Azure Web Apps, Account Storage, and Azure SQL 	Databases, we ensure optimal performance and reliable data management.',
					},
					{
						title: '',
						content:
							'ASP.NET Back-End Development: The GreenBox platform is powered by the versatility and 	efficiency of ASP.NET, delivering a robust back-end infrastructure that seamlessly handles 	complex logistics operations.',
					},
					{
						title: '',
						content:
							'Angular Front-End Development: We employ Angular, a modern and dynamic front-end framework, to create a user-friendly and intuitive interface for GreenBox. This ensures a seamless user experience and efficient interaction with the platform’s functionalities.',
					},
					{
						title: '',
						content:
							'Mobile App Development: GreenBox extends its reach through mobile applications developed 	using Xamarin for iOS and Java for Android. These native apps enable collection teams to 	leverage the power of their mobile devices, enhancing productivity and facilitating real-time 	tracking and reporting.',
					},
					{
						title: '',
						content:
							"Geolocation Technologies: Specialized geolocation tags enrich the platform's capabilities, 	enabling precise tracking of collection points and optimizing route planning. This ensures 	efficient navigation for the collection teams, saving time and reducing costs.",
					},
					{
						title: '',
						content:
							'Near Field Communication (NFC): By integrating NFC technology into the GreenBox solution, we 	enable seamless collection tracking and reporting. The collection teams can easily interact with 	NFC-enabled devices to record and transmit vital data, streamlining the overall logistics process.',
					},
				],
			},
		},
		additionalSections: [
			{
				title: 'Sustaining Excellence: Ensuring Reliability, Performance Monitoring, and Ongoing Support',
				content: [
					'At Kodin Soft, our commitment to excellence extends beyond the initial implementation of the GreenBox platform. We prioritize the reliability, performance, and continuous improvement of our solutions to provide long-term value to our clients.',
					"To ensure the platform's reliability, we employed Azure tools, such as Application Insights, for comprehensive monitoring and troubleshooting. This proactive approach allowed us to identify and address any potential issues promptly, minimizing disruptions and maximizing uptime. Thorough testing was conducted throughout the development process, ensuring that the platform met all requirements and operated flawlessly.",
					"Once deployed across various environments, we maintained a vigilant eye on the platform's performance. Through continuous monitoring, we tracked its behavior and performance metrics, ensuring that it consistently delivered the intended benefits to Green Team. This proactive approach enabled us to detect and address any performance bottlenecks or anomalies promptly, optimizing the platform's efficiency and effectiveness.",
					"Furthermore, we highly value user feedback and leverage analytics data to drive ongoing optimization. By actively listening to our clients' needs and preferences, we iteratively enhance the platform's integration, features, and usability. This user-centric approach allows us to refine GreenBox based on real-world insights, ensuring that it remains aligned with evolving industry trends and client expectations.",
					"By following this comprehensive implementation strategy, we have successfully delivered a scalable, reliable, and feature-rich platform that meets Green Team's needs and exceeds expectations. Our commitment to sustaining excellence extends beyond the initial implementation, as we continue to provide ongoing support, enhancements, and proactive maintenance to ensure the long-term success of the GreenBox platform.",
				],
			},
			{
				title: "Driving Results and Unlocking Benefits: Empowering Green Team's Success",
				content: [
					"Through our professional development expertise and integration of the Azure tech stack, GreenBox has enabled Green Team to achieve significant savings in operational time and costs. With optimized route planning, intelligent location choices, and reduced manual workloads, the platform has streamlined operations and increased efficiency. Moreover, the platform's analytics capabilities have improved bonus allocation and empowered better-informed decision-making at all levels. Our partnership with Green Team stands as a testament to our commitment to delivering transformative solutions that drive success in the logistics industry.",
				],
			},
		],
		techStack: {
			backgroundColor: '#11b549',
			backgroundImage: greenbnoxBackgroundImage,
			imageStyles: {
				marginTop: '7rem',
			},
			stack: [
				{
					icon: cSharpIcon,
					name: 'C#',
					alt: 'csharp-icon',
				},
				{
					icon: angularIcon,
					name: 'Angular',
					alt: 'angular-icon',
				},
				{
					icon: dotNetIcon,
					name: '.Net',
					alt: 'dotnet-icon',
				},
				{
					icon: xamarinIcon,
					name: 'Xamarin',
					alt: 'xamarin-icon',
				},
				{
					icon: googleMapsIcon,
					name: 'Google Maps API',
					alt: 'google-maps-icon',
				},
			],
			azure: [
				{
					icon: azureStorageIcon,
					name: 'Storage',
					alt: 'azure-storage-icon',
				},
				{
					icon: azureSQLIcon,
					name: 'SQL',
					alt: 'sql-icon',
				},
				{
					icon: azureWebAppsIcon,
					name: 'Web apps',
					alt: 'azure-web-icon',
				},
			],
		},
	},
	{
		project: 'Campaign Printing Programmatic Platform',
		title: 'Revolutionizing Direct Mail Marketing',
		intro: 'In the rapidly evolving world of marketing, the integration of traditional and digital strategies is crucial for creating comprehensive campaigns that engage customers across multiple touchpoints. We are dedicated to developing a pioneering solution with innovative Programmatic Print technology, seamlessly blending the tangibility of direct mail with the precision and flexibility of digital marketing.',
		img: pppIntroImage,
		alt: 'ppp-intro-image',
		url: 'ppp',
		content: {
			client: {
				img: pppMailDocumentsImage,
				alt: 'ppp-mail-documents-img',
				title: 'Problem Statement and Solution Overview',
				content: [
					'Marketers have long faced the challenge of bridging the gap between the personal touch of traditional direct mail and the dynamic targeting capabilities of digital channels. The limitations of conventional direct mail—such as static content, lengthy production times, and difficulty measuring ROI—often resulted in missed opportunities for personalized engagement and optimized marketing performance. ',
					'The solution we develop addresses these challenges by offering an automated and personalized direct mail solution that integrates with digital marketing strategies. Through Programmatic Print technology we enable marketers to trigger/push direct mail campaign pieces as part of a unified customer journey, leveraging data-driven insights to deliver personalized content at scale. ',
				],
			},
			success: {
				img: pppPrintButtonImage,
				alt: 'ppp-print-button-img',
				title: 'Efficient Execution: How We Brought It to Life?',
				content: [
					'At Kodin Soft, we understand the importance of aligning our services with the unique requirements of our clients. Within the project, we adopted a client-centric approach, working closely with the client team to identify and address their specific business processes and workflows. This collaborative effort allowed us to determine the precise functionalities that the platform should automate or support, ensuring a tailored solution that truly meets their evolving needs. There were numerous methods to accomplish this task, but only one proved both cost-effective and performance-efficient for the client – the monorepository approach, coupled with a comprehensive AWS development and infrastructure setup. The plethora of Node.js packages underscored the significance of tools like NX build and PNPM. Hosting different applications on distinct routes was where AWS made its most significant contribution.',
				],
				// bulletsDesc: [
				// 	'Goods Collection Routes Planning: GreenBox automates the process of planning optimal collection routes, ensuring efficient and cost-effective operations. It intelligently determines the best collection points to visit and suggests the most optimized routes, leveraging historical data and advanced algorithms. ',
				// 	'Intelligent Time and Location Proposal: Leveraging a wealth of historical information and sophisticated analysis, GreenBox proposes the ideal time and location for pick-ups, enabling teams to maximize productivity while minimizing travel time and costs. ',
				// 	'Collection Tracking and Reporting: GreenBox incorporates cutting-edge NFC technology and GPS integration, enabling real-time tracking and reporting of collections. This ensures transparency, accountability, and seamless coordination between collection teams and warehouses. ',
				// 	'Streamlined Maintenance Tasks: GreenBox goes beyond logistics and offers maintenance task proposals and execution functionalities. This allows for efficient handling of maintenance tasks at collection locations, ensuring their optimal operational condition. ',
				// 	'Comprehensive Financial and Management Reporting: GreenBox provides extensive reporting capabilities, offering valuable insights into financial performance and management metrics. By presenting clear and concise reports, it empowers decision-makers to make informed strategic choices.',
				// ],
			},
			implementation: {
				title: 'Tailored Solutions: A Client-Centric Approach to Address Evolving Needs',
				intro: [
					"We worked closely with the client to identify the various business processes and workflows that the platform would automate or support. Additionally, we carefully determined the requirements for the web interface, including the types of queries it should handle, the desired level of personalization, and the expected response time. This collaborative effort helped shape the design and development process by clearly defining the project's scope, goals, and objectives.",
				],
				sections: [
					{
						title: 'Selecting the Ideal Cloud Technologies for Optimal Performance and Efficiency ',
						content:
							'To build efficient and scalable solution, we recommended adopting a cloud-native development approach. This approach involves building and running applications that fully leverage the advantages of cloud computing. Cloud-native applications are designed to operate on cloud infrastructure, utilizing cloud services and technologies, while adhering to a set of principles and best practices that facilitate rapid development, deployment, and scaling.',
					},
					{
						title: 'Crafting a Seamless User Experience and Robust API ',
						content:
							"Given the project's specific requirements and use cases, selecting appropriate front-end and back-end technologies was crucial. The entire project ecosystem was categorized into three critical domain segments – the marketing website, back-office, and the main platform. The main platform required a hybrid approach for serving page content, leading us to select Next.js for implementation. Angular was employed for the back-office, while the marketing website utilized Next.js entirely on the server-side due to finely tuned SEO optimizations. Our relentless pursuit of innovation led us to implement an OpenAPI Generator for our RESTful API, enhancing scalability and reducing the learning curve for new team members joining our project.",
					},
					{
						title: 'Building a Resilient Cloud Infrastructure for Scalability and Security ',
						content:
							'We seamlessly handled the user management flow by integrating the AWS Cognito identity provider service. Data was stored in DynamoDB, a NoSQL indexed database, granting us the flexibility to manage even the most intricate campaign data structures. To streamline operations, we developed an Express.js middleware bridging the AWS API Gateway, Next.js, and AWS DynamoDB. All applications, along with assets and files, were deployed to S3, then distributed via Route 53 and hosted through CloudFormation. Our DevOps procedures were automated using SST.',
					},
					{
						title: 'Sustaining excellence: Ensuring Reliability, Monitoring Performance, and Providing Ongoing Support. ',
						content:
							"In order to guarantee peak performance and preemptively address any potential issues, we leveraged AWS tools like AWS CloudWatch for comprehensive monitoring and logging. Employing AWS Step Functions enabled the business to monitor complex business and technical processes seamlessly executed by AWS Lambda Functions. Extensive Playwright end-to-end testing was conducted to validate that the platform met all criteria and operated flawlessly. Post-deployment to production, we vigilantly monitored its performance to ensure it consistently delivered the anticipated benefits. Moreover, we prioritized optimizing the integration based on user feedback and analytics data, thereby augmenting the platform's effectiveness even further.",
					},
				],
			},
		},
		techStack: {
			backgroundColor: '#F3B74E',
			backgroundImage: pppStatementPrintingImage,
			imageStyles: {
				marginTop: '7rem',
			},
			stack: [
				{
					icon: nextIcon,
					name: 'Next.js',
					alt: 'next-icon',
				},
				{
					icon: nodeIcon,
					name: 'Node.js',
					alt: 'node-icon',
				},
				{
					icon: typeScriptIcon,
					name: 'Typescript',
					alt: 'typescript-icon',
				},
				{
					icon: openAPIGeneratorIcon,
					name: 'Open API Generator',
					alt: 'open-api-generator',
				},
			],
			aws: [
				{
					icon: awsApiGatewayIcon,
					name: 'API Gateway',
					alt: 'aws-api-gateway',
				},
				{
					icon: awsCognitoIcon,
					name: 'Cognito',
					alt: 'aws-cognito-icon',
				},
				{
					icon: awsS3Icon,
					name: 'S3 Bucket',
					alt: 'aws-s3-icon',
				},

				{
					icon: awsStepFunctionsIcon,
					name: 'Step Functions',
					alt: 'aws-step-functions',
				},
				{
					icon: awsLambdaIcon,
					name: 'Lambda',
					alt: 'aws-lambda-icon',
				},
				{
					icon: awsCloudFormationIcon,
					name: 'Cloud Formation',
					alt: 'aws-cloud-formation-icon',
				},
				{
					icon: awsRoute53Icon,
					name: 'Route 53',
					alt: 'aws-route-53',
				},
				{
					icon: awsCloudWatchIcon,
					name: 'Cloud Watch',
					alt: 'aws-cloud-watch',
				},
				{
					icon: awsAppSyncIcon,
					name: 'App Sync',
					alt: 'aws-app-sync',
				},
				{
					icon: awsAmplifyIcon,
					name: 'Amplify',
					alt: 'aws-amplify',
				},
				{
					icon: awsDynamoDBIcon,
					name: 'Dynamo DB',
					alt: 'aws-dynamo-db',
				},
			],
		},
	},
	{
		project: 'HomePort – Revolutionizing Earth Observation Data Access with Cloud-Based Satellite Integration',
		title: 'Challenge',
		intro: 'Access to Earth observation data has traditionally been limited by high costs, complex logistics, and technical barriers, restricting its benefits primarily to large organizations with specialized resources. HomePort seeks to address this challenge by providing a secure, scalable, and user-friendly platform for Earth data acquisition and management.',
		img: homePortCoverImage,
		alt: 'home-pot-intro-image',
		url: 'homePort',
		content: {
			client: {
				img: homePortImageBg1,
				alt: 'home-pot-img',
				title: 'Problem Statement',
				content: [
					'Across industries, from environmental conservation to commercial enterprises, organizations require streamlined, secure, and customizable access to Earth observation data. These data needs are diverse, spanning environmental monitoring, scientific research, disaster response, and operational planning. The challenge lies in providing a platform that enables organizations to harness satellite data affordably, without the infrastructure burdens or complexities of direct satellite operation, while allowing them to tailor data requests for their specific needs.',
				],
			},
			success: {
				img: homePortImageBg,
				alt: 'home-pot-img',
				title: 'Approach',
				content: [
					'HomePort has developed a decentralized, cloud-native platform that consolidates multiple satellite data providers into a single, intuitive interface. By leveraging Amazon Web Services (AWS) technologies, HomePort offers secure, efficient access to satellite data, enabling users to perform mission planning and real-time data acquisition with ease:',
				],
				bulletsDesc: [
					'**User Authentication and Access Control with AWS Amplify:**\n AWS Amplify provides a robust authentication layer, allowing users to securely log into HomePort, access mission planning tools, and manage data based on specific roles and permissions.',
					'**Integrated Satellite Tasking and Data Provider Collaboration:**\n Through partnerships with leading satellite providers, including Capella Space, HomePort allows users to define targeted areas, schedule satellite imaging tasks, and retrieve high-resolution data tailored to their requirements.',
				],
			},
			implementation: {
				title: 'Implementation:',
				subtitle: 'Solution',
				intro: [
					"HomePort’s technology stack is built on AWS cloud services, integrated with external satellite providers to deliver a comprehensive Earth observation platform.",
				],
				sections: [
					{
						title: ' AWS Amplify for User Authentication and Access Management',
						content:
							'Provides a secure, scalable portal where users can easily sign up, log in, and manage mission-critical tools for satellite tasking and data acquisition.',
					},
					{
						title: 'API Gateway and AWS Lambda for Back-End Coordination',
						content:
							"These services manage communication between HomePort’s interface and satellite providers, allowing users to make real-time tasking requests and ensuring smooth data exchange between the front end and back-end systems.",
					},
					{
						title: 'Data Management with DynamoDB and Amazon S3',
						content:
							'DynamoDB serves as the primary database for mission metadata, while Amazon S3 securely stores large volumes of satellite imagery, offering reliable, scalable storage that supports diverse user needs.',
					},
					{
						title: 'Event Automation with EventBridge and CloudWatch Monitoring',
						content:
							"EventBridge automates task execution workflows, while CloudWatch provides real-time monitoring for enhanced data availability and system reliability, alerting users to key events or issues as they arise.",
					},
				],
			},
		},
		additionalSections: [
			{
				title: 'Results',
				content: [
					'HomePort’s cloud-based platform provides unprecedented accessibility, customization, and efficiency for Earth observation data.',
					'**Enhanced Data Accessibility and Customization:**\nHomePort’s integration with multiple satellite providers allows users to request on-demand, custom imaging, making Earth observation data more accessible and tailored to their needs than ever before.',
					"**Improved User Experience:**\nAWS Amplify simplifies user management, streamlining access and tasking operations, while the platform’s unified dashboard offers a seamless interface for scheduling and managing satellite data.",
					"**Cost Efficiency and Security:**\nLeveraging cloud-based storage and processing enables organizations to access high-quality satellite data without the expense of dedicated infrastructure, all while maintaining stringent data security and mission privacy.",
				],
			},
			{
				title: "Key Takeaways",
				content: [
					'**Unified, Cloud-Native Platform:**\nHomePort’s platform consolidates multiple satellite data sources and AWS services, creating a central hub for mission planning, data management, and tasking in a single, accessible interface.',
					"**Scalable, Secure Infrastructure:**\nHomePort’s infrastructure, powered by AWS, ensures scalable data access and management, accommodating organizations with diverse data needs and mission requirements while prioritizing security and affordability.",
				],
			},
			{
				title: "Conclusion",
				content: [
					'HomePort is redefining access to Earth observation data through a secure, integrated cloud platform. By leveraging advanced AWS technologies and partnering with premier satellite providers, HomePort meets the demand for accessible, reliable Earth data across commercial, research, and conservation fields. This platform empowers organizations of all sizes to make informed, data-driven decisions, marking a significant step forward in the democratization of Earth observation resources.',
				],
			},
		],
		techStack: {
			backgroundColor: '#1e3556',
			backgroundImage: homePortDiagramImage,
			imageStyles: {
				paddingTop: '2rem',
				paddingBottom: '2rem',
			},
			stack: [
				{
					icon: vueJsIcon,
					name: 'Vue.js',
					alt: 'vue-icon',
				},
				{
					icon: javaIcon,
					name: 'Java',
					alt: 'java-icon',
				},
				{
					icon: typeScriptIcon,
					name: 'Typescript',
					alt: 'typescript-icon',
				},
				{
					icon: openAPIGeneratorIcon,
					name: 'Open API Generator',
					alt: 'open-api-generator',
				},
			],
			aws: [
				{
					icon: awsApiGatewayIcon,
					name: 'API Gateway',
					alt: 'aws-api-gateway',
				},
				{
					icon: awsCognitoIcon,
					name: 'Cognito',
					alt: 'aws-cognito-icon',
				},
				{
					icon: awsS3Icon,
					name: 'S3 Bucket',
					alt: 'aws-s3-icon',
				},
				{
					icon: awsLambdaIcon,
					name: 'Lambda',
					alt: 'aws-lambda-icon',
				},
				{
					icon: awsCloudWatchIcon,
					name: 'Cloud Watch',
					alt: 'aws-cloud-watch',
				},
				{
					icon: awsAmplifyIcon,
					name: 'Amplify',
					alt: 'aws-amplify',
				},
				{
					icon: awsDynamoDBIcon,
					name: 'Dynamo DB',
					alt: 'aws-dynamo-db',
				},
			],
		},
	},
];
