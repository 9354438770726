import styled from "@emotion/styled";
import squareImage from "../../assets/images/squares.svg";

interface ParentProps {
  img: string;
  alt: string;
  title: string;
  description: string;
  reverse: boolean;
  position: ImagePosition;
}

interface ImagePosition {
  left?: string;
  right?: string;
  bottom?: string;
  top?: string;
}

export const ImageTextCard = ({
  img,
  alt,
  title,
  description,
  reverse,
  position,
}: ParentProps) => {
  return (
    <Wrapper style={{ flexDirection: reverse ? "row-reverse" : "row" }}>
      <ImageContainer>
        <Image
          src={img}
          alt={alt}
          title="cover-image"
          width="auto"
          height="auto"
          loading="eager"
        />
        <SquareImage
          src={squareImage}
          alt="square-image"
          style={position}
          title="cover-mask"
          width="auto"
          height="auto"
          loading="lazy"
        />
      </ImageContainer>

      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 83%;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 6rem;
  padding-bottom: 7rem;
  @media screen and (max-width: 1130px) {
    gap: 3.5rem;
  }
  @media screen and (max-width: 850px) {
    flex-direction: column !important;
  }
`;

const ImageContainer = styled.div`
  position: relative;
`;
const Image = styled.img`
  width: 300px;
  height: 300px;
  @media screen and (min-width: 1500px) {
    width: inherit;
    height: inherit;
  }
`;
const SquareImage = styled.img`
  width: 15rem;
  height: 15rem;
  position: absolute;
`;
const TextContainer = styled.div``;

const Title = styled.h2`
  color: #1c232c;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: calc(46px + (48 - 18) * ((100vw - 1024px) / (1920 - 1024)));
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
`;
const Description = styled.p`
  color: #445062;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: calc(20px + (20 - 16) * ((100vw - 768px) / (2560 - 768)));
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
`;
