import React from "react";
import styled from "@emotion/styled";
import TextWithTitle from "../components/layout-elements/TextWithTitle.component";
import ProjectComponent from "../components/projects/simple-project-layout/ProjectComponent.component";
import {
  CardsWrapper,
  OfferSection,
} from "../components/proposal/ProposalSection.component";
import { MOBILE_WIDTH_SCREEN } from "../utils";
import useMobileScreen from "../hooks/useMobileScreen";
import CardWithIcon from "../components/layout-elements/CardWithIcon.component";
import TechStack from "../components/tech-stack/TechStack.component";
import { cardsData } from "../store/storeAllData";
import { Helmet } from "react-helmet-async";

const customHeroTitleStyle = {
  title: {
    fontSize: "calc(56px + (56 - 48) * ((100vw - 1024px) / (1920 - 1024)))",
    lineHeight: "72px",
  },
  description: {
    fontSize: "calc(20px + (20 - 12) * ((100vw - 1024px) / (1920 - 1024)))",
    lineHeight: "34px",
    width: "95%",
  },
  container: {
    paddingTop: "60px",
  },
};

const ExpertisePage = () => {
  const isMobile = useMobileScreen(MOBILE_WIDTH_SCREEN);

  return (
    <Wrapper>
      <Helmet>
        <title>What we do - Kodin Soft</title>
        <meta
          name="description"
          content="Our team of software experts specialize in web, mobile, backend, database and could technologies."
        />
        <link rel="canonical" href="https://www.kodinsoft.net/expertise" />
      </Helmet>
      <FirstContainer>
        <TextWithTitle
          title="What we do"
          description="Our team of software experts specialize in web, mobile, backend, database and could technologies."
          showButton={false}
          customStyles={customHeroTitleStyle}
          type="h1"
        />
        <CardsWrapper>
          {cardsData.map((item, i) => (
            <CardWithIcon
              key={i}
              title={item.title}
              icon={item.icon}
              alt={item.alt}
              link={item.link}
              style={{ iconWidth: "initial" }}
            />
          ))}
        </CardsWrapper>
        <TechStack />
      </FirstContainer>
      <SecondContainer>
        <InnerContainer>
          <OfferSection isMobile={isMobile} />
          <ProjectComponent width="100%" />
        </InnerContainer>
      </SecondContainer>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  padding-top: 3rem;
  background: #f8f9f9;
`;

const FirstContainer = styled.div`
  width: 83%;
  margin: auto;
  padding-bottom: 8rem;
  @media screen and (max-width: 1024px) {
    width: 89%;
  }
`;

const SecondContainer = styled.div`
  margin: auto;
  background: #eef2f9;
  padding-top: 5.5rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 9rem;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    row-gap: 4rem;
  }
`;
const InnerContainer = styled.div`
  width: 83%;
`;

export default ExpertisePage;
