import styled from "@emotion/styled";
import logoDotImage from "../../assets/images/approach/kodin-soft-dot.svg";
import multipleDotsImage from "../../assets/images/approach/multiple-dots.svg";
import kodinShadow from "../../assets/images/approach/kodin-shadow.svg";
import kShadowImage from "../../assets/images/approach/k-shadow.svg";

export const HeroImage = () => {
  return (
    <Wrapper>
      <LogoDot
        src={logoDotImage}
        alt={"kodinsoft-logo-with-dot"}
        width="auto"
        height="auto"
        title="logo-mask"
        loading="eager"
      />
      <Container>
        <DotsImage
          src={multipleDotsImage}
          alt={"multiple-dots"}
          width="auto"
          height="auto"
          title="mask"
          loading="eager"
        />
        <KodinShadow
          src={kodinShadow}
          alt={"kodin-shadow"}
          width="auto"
          height="auto"
          title="shadow 1"
          loading="eager"
        />
        <KKodinShadow
          src={kShadowImage}
          alt={"k-kodin-shadow"}
          width="auto"
          height="auto"
          title="shadow 2"
          loading="eager"
        />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  justify-self: end;
  align-self: end;
  position: absolute;
  @media screen and (max-width: 1220px) {
    margin-right: -5%;
  }
  @media screen and (max-width: 1024px) {
    margin-right: -30%;
  }
  @media screen and (max-width: 860px) {
    position: relative;
    margin-right: 0;
  }
  @media screen and (max-width: 640px) {
    margin-right: -50%;
  }

  @media screen and (max-width: 440px) {
    margin-right: -63%;
  }
`;
const Container = styled.div``;
const LogoDot = styled.img`
  position: absolute;
  bottom: 0;
`;
const DotsImage = styled.img`
  @media screen and (max-width: 360px) {
    display: none;
  }
`;
const KodinShadow = styled.img``;
const KKodinShadow = styled.img`
  position: absolute;
  right: 0;
`;
