import styled from "@emotion/styled";

export const HeroTitle = () => {
  return (
    <Wrapper>
      <Title>Our approach</Title>
      <Description>
        Designing and developing software solutions requires a structured and
        disciplined approach to ensure that the final product meets the
        requirements and expectations of the client.
        <br /> <br />
        Here are some key factors to consider when designing and developing
        software solutions
      </Description>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 75%;
  justify-self: end;
  @media screen and (max-width: 1220px) {
    justify-self: center;
  }

  @media screen and (max-width: 860px) {
    margin-top: 6rem;
  }
`;

const Title = styled.h1`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: calc(60px + (60 - 56) * ((100vw - 1024px) / (1920 - 1024)));
  line-height: 72px;
  color: white;
`;

const Description = styled.p`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: white;
`;
