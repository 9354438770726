import React from "react";
import styled from "@emotion/styled";

import dinkoImage from "../../assets/images/dinko-image.png";
import linkedInIcon from "../../assets/images/icons/linkedIn-icon.svg";
import squareImage from "../../assets/images/squares.svg";

/**
 *
 * TODO: Change the linkedIn color to gradient and maybe the icon also
 *
 */

const BiographyContainer = () => {
  return (
    <Wrapper>
      <ImageContainer>
        <SquareImage
          src={squareImage}
          alt="square-image"
          title="square-mask-image"
          width="auto"
          height="auto"
          loading="lazy"
        />
        <Image
          src={dinkoImage}
          alt="image"
          title="biography-image"
          width="auto"
          height="auto"
          loading="eager"
        />
        <Name>Dinko Manolov</Name>
        <Position>CTO</Position>
      </ImageContainer>
      <DescriptionContainer>
        <Description>
          “We have some of the best software engineers out there. The level of
          passion and experience they bring to every project guarantees that our
          partners don’t have to be scared of the future.”.
        </Description>
        <ButtonWrapper>
          <Icon
            src={linkedInIcon}
            alt="linked-in-icon"
            title="linked-in-icon"
            width="auto"
            height="auto"
            loading="lazy"
          />
          <Button>
            <a
              style={{
                textDecoration: "none",
                color:
                  "linear-gradient( 86.33deg, #1244c5 0.53%, #225aeb 50.08%, #2490f4 96.62% )",
              }}
              href="https://www.linkedin.com/in/dinkomanolov/"
              target="_blank"
              rel="noreferrer"
            >
              See Dinko’s LinkedIn profile 🡵
            </a>
          </Button>
        </ButtonWrapper>
      </DescriptionContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 3.75rem;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
const ImageContainer = styled.div``;
const SquareImage = styled.img`
  width: 19.875rem;
  height: 19.875rem;
  position: absolute;
  margin-top: 7.25rem;
  margin-left: 4.875rem;
  @media screen and (max-width: 1024px) {
    margin-top: -7.75rem;
    margin-left: 5.875rem;
  }
`;

const Image = styled.img`
  width: 17.625rem;
  height: 19.75rem;
  mix-blend-mode: luminosity;
  object-fit: contain;
`;

const Name = styled.p`
  font-family: "Outfit";
  font-size: 1.5rem;
  line-height: 2.125rem;
  display: flex;
  align-items: center;
  color: #1c232c;
`;
const Position = styled.p`
  font-family: "Outfit";
  font-size: 1.25rem;
  line-height: 1.625rem;
  display: flex;
  align-items: center;
  color: #445062;
`;
const DescriptionContainer = styled.div`
  width: 66%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;
const Description = styled.p`
  font-family: "Outfit";
  font-size: calc(34px + (34 - 30) * ((100vw - 1024px) / (1920 - 1024)));
  line-height: 3rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: absolute;
  right: 7.5rem;
  margin-top: 2.5rem;

  @media screen and (max-width: 1024px) {
    position: inherit;
  }
`;
const Button = styled.button`
  border: none;
  background: transparent;
  letter-spacing: 0.2px;
  color: #1c232c;
  font-family: "Outfit";
  font-size: 1.25rem;
`;
const Icon = styled.img``;

export default BiographyContainer;
