import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import NavItemProps from "../../../../model/interfaces/header/NavItemProps";

const NavButton = (props: NavItemProps) => {
  const { link, children, style, isInvertStyle, isScrolling } = props;

  const Content = styled.div`
    display: flex;
    justify-content: center;
  `;

  const ButtonWrapper = styled.a<any>`
    color: ${(props) =>
      props.isInvertStyle && !props.isScrolling ? "#225aeb" : "#f6fcff"};
    background: ${(props) =>
      props.isInvertStyle && !props.isScrolling ? "#f6fcff" : "#225aeb"};
    padding: 8px;
    text-decoration: none;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    font-family: "Outfit";
    /* width: 7%; */
    width: 8rem;
    box-shadow: 0px 8px 16px rgba(21, 24, 28, 0.08);

    &:hover {
      color: ${(props) =>
        props.isInvertStyle && !props.isScrolling ? "#225aeb" : "#f6fcff"};
    }
  `;

  return (
    <ButtonWrapper
      href={"#" + link}
      style={style}
      isInvertStyle={isInvertStyle}
      isScrolling={isScrolling}
    >
      <Content>{children}</Content>
    </ButtonWrapper>
  );
};
NavButton.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};
export default NavButton;
