import React from "react";
import styled from "@emotion/styled";
import leftImage from "../../assets/images/kodin-card-left.png";
import rightImage from "../../assets/images/kodin-card-right.png";
import bulb from "../../assets/images/light-bulb.png";
import rocket from "../../assets/images/rocket-launch.png";
import subtract from "../../assets/images/Subtract.png";
import ContactForm from "../contacts/contact-form/ContactForm";
import logo from "../../assets/images/icons/footer-logo.svg";
import NavItem from "../header/navigation/nav-item/NavItem";

import mobilePhoneIcon from "../../assets/images/icons/footer-mobile.svg";
import emailIcon from "../../assets/images/icons/footer-email.svg";
import locationIcon from "../../assets/images/icons/footer-location.svg";
import linkedinIcon from "../../assets/images/icons/footer-linkedin.svg";
import {
  ContactListType,
  NavItemsType,
} from "../../model/interfaces/footer/FooterTypes";
import {
  DescriptionProps,
  IContactItem,
  INavItems,
} from "../../model/interfaces/footer/IFooter";
import { MOBILE_WIDTH_SCREEN } from "../../utils";
import useMobileScreen from "../../hooks/useMobileScreen";
import ClutchWidget from "./ClutchWidget.component";

const navItemStyle = {
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "18px",
};

const navItems = [
  { name: "Home", link: "/" },
  { name: "Approach", link: "/approach" },
  { name: "Who we are", link: "/about" },
  { name: "Expertise", link: "/expertise" },
  { name: "Services", link: "/services" },
  { name: "Projects", link: "/projects" },
];

const contactItems = [
  {
    icon: mobilePhoneIcon,
    content: "+359 88 3605555",
    alt: "Mobile",
    href: "tel:+359 88 3605555",
  },
  {
    icon: emailIcon,
    content: "info@kodinsoft.net",
    alt: "Email",
    href: "mailto:info@kodinsoft.net",
  },
  {
    icon: locationIcon,
    content: "Sofia, Bulgaria",
    alt: "Location",
  },
  {
    icon: linkedinIcon,
    content: "LinkedIn / Kodin Soft",
    alt: "LinkedIn",
    href: "https://www.linkedin.com/company/kodin-soft-ltd/",
    newPage: true,
  },
];

const NavList = ({ items }: NavItemsType) => (
  <FooterNavigation>
    {items.map((item: INavItems, i: number) => (
      <NavItem key={i} style={navItemStyle} link={item.link}>
        {item.name}
      </NavItem>
    ))}
    <li>
      <ContactNavItem href="#contact">Contact us</ContactNavItem>
    </li>
  </FooterNavigation>
);

const ConditionalLinkWrapper = ({ condition, wrapper, children }: any) =>
  condition ? wrapper(children) : children;

const ContactList = ({ items }: ContactListType) => {
  return (
    <>
      {items.map((item: IContactItem, i: number) => (
        <ContactItem key={i}>
          <ContactIcon
            src={item.icon}
            alt={item.alt}
            title="contact-icon"
            width="auto"
            height="auto"
            loading="eager"
          />
          <ConditionalLinkWrapper
            condition={item.href}
            wrapper={(children: any) => (
              <a
                href={item.href}
                target={item.newPage ? "_blank" : "_self"}
                rel="noreferrer"
                style={{
                  color: "#445062",
                  textDecoration: "none",
                }}
              >
                {children}
              </a>
            )}
          >
            <ContactTextItem>{item.content}</ContactTextItem>
          </ConditionalLinkWrapper>
        </ContactItem>
      ))}
    </>
  );
};

const DescriptionList = ({ icon, text, alt }: DescriptionProps) => {
  return (
    <DescriptionContent>
      <Icon
        src={icon}
        alt={alt}
        title="description-icon"
        width="auto"
        height="auto"
        loading="lazy"
      />
      <Content>{text}</Content>
    </DescriptionContent>
  );
};

const Footer = () => {
  const isMobile = useMobileScreen(MOBILE_WIDTH_SCREEN);

  return (
    <FooterWrapper>
      <ContactFormWrapper id="contact">
        {!isMobile && (
          <BackgroundImage
            src={leftImage}
            alt="left-cover-logo"
            title="cover-mask"
            width="auto"
            height="auto"
            loading="lazy"
          />
        )}
        <DescriptionWrapper>
          <ContentHeader>Let’s &lt;get started&gt;</ContentHeader>
          {content?.map((item, i) => (
            <DescriptionList
              key={i}
              icon={item.icon}
              text={item.text}
              alt={item.alt}
            />
          ))}
        </DescriptionWrapper>
        <ContactForm />
        {!isMobile && (
          <BackgroundImage
            src={rightImage}
            alt="right-cover-logo"
            title="cover-mask"
            width="auto"
            height="auto"
            loading="lazy"
          />
        )}
      </ContactFormWrapper>

      <BottomSection>
        <Container>
          <QuoteWrapper>
            <Image
              src={logo}
              alt="Logo"
              title="footer-logo"
              width="auto"
              height="auto"
              loading="eager"
            />
            <Text>Designing and Developing software solutions</Text>
            <ClutchWidget />
          </QuoteWrapper>

          <SitemapWrapper>
            <WrapperFooterMenu>
              {!isMobile && <NavList items={navItems} />}
              <ContactCard>
                <SubText>Contacts</SubText>
                <ContactList items={contactItems} />
              </ContactCard>
            </WrapperFooterMenu>
            <CopyRight>
              Copyright © 2022. Kodin Soft. All rights reserved.
            </CopyRight>
          </SitemapWrapper>
        </Container>
      </BottomSection>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.section`
  background-color: #f5fcff;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const ContactFormWrapper = styled.div`
  background-color: #0d3394;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding-top: 0px;
    padding-bottom: 3.75rem;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  z-index: 5;
  :first-of-type {
    width: 22rem;
    left: 0.1rem;
  }
  :last-child {
    right: 0.1rem;
    width: 23rem;
    height: 33rem;
  }
`;

const DescriptionWrapper = styled.div`
  width: 51%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 1.25rem;
    margin-top: 3.75rem;
  }

  @media screen and (min-width: 1599px) {
    width: 40%;
  }
`;

const ContentHeader = styled.h3`
  height: 7.5rem;

  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: calc(38px + (38 - 18) * ((100vw - 1024px) / (1920 - 1024)));
  line-height: 3rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  color: #ffffff;
`;

const DescriptionContent = styled.div`
  display: flex;
  gap: 1.5rem;
`;

const Icon = styled.img`
  width: 1.875rem;
  height: 1.875rem;
`;

const Content = styled.p`
  width: 35.188rem;
  height: 1.75rem;
  font-family: "Outfit";
  font-style: normal;
  line-height: 1.75rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: rgba(248, 249, 249, 0.72);
  font-size: calc(15px + (15 - 13) * ((100vw - 1024px) / (1920 - 1024)));
`;

const BottomSection = styled.footer`
  background: #f0f4fa;
  padding-top: 6rem;
  padding-bottom: 6.5rem;
  @media screen and (max-width: 1024px) {
    padding-bottom: 0;
  }
`;

const Container = styled.div`
  width: 83%;
  display: flex;
  margin: auto;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
const QuoteWrapper = styled.div``;

const Image = styled.img``;
const Text = styled.p`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: calc(60px + (60 - 56) * ((100vw - 1024px) / (1920 - 1024)));
  line-height: 4.5rem;
  margin-top: 1.75rem;
  padding-bottom: 4rem;

  @media screen and (max-width: 1024px) {
    line-height: inherit;
    font-size: calc(40px + (40 - 32) * ((100vw - 375px) / (1024 - 375)));
  }

  @media screen and (min-width: 1023px) and (max-width: 1180px) {
    font-size: calc(46px + (46 - 18) * ((100vw - 1024px) / (1920 - 1024)));
  }
`;
const SitemapWrapper = styled.div`
  width: 52%;
  max-width: 33rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const SubText = styled.p`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.375rem;
`;

const FooterNavigation = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  align-items: center;

  @media screen and (max-width: 1024px) {
    margin-top: 3rem;
    flex-direction: column;
    row-gap: 24px;
  }
`;
const WrapperFooterMenu = styled.div`
  display: "flex";
  flex-wrap: "wrap";
  justify-content: "space-between";
`;
const ContactCard = styled.div`
  background-color: #ffffff;
  width: 33rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 2rem;
  box-shadow: 0px 24px 72px rgba(21, 24, 28, 0.08);
  backdrop-filter: blur(12px);

  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(
      217deg,
      rgba(214, 222, 235, 1),
      rgba(248, 249, 249, 1)
    )
    1;
  @media screen and (max-width: 1024px) {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding-left: 0;
  }
`;

const ContactItem = styled.div`
  display: flex;
  column-gap: 1.25rem;
  align-items: center;
`;

const ContactIcon = styled.img``;

const ContactTextItem = styled.p`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #445062;
`;
const CopyRight = styled.p`
  color: #6d819d;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  padding-bottom: 1rem;
`;

const ContactNavItem = styled.a`
  font-family: "Outfit", sans-serif;
  color: rgb(136, 141, 153);
  text-decoration: none;
  font-size: 0.9rem;
  line-height: 1rem;
  &:hover {
    color: #1c232c;
  }
`;

export default Footer;

const content = [
  {
    icon: subtract,
    alt: "subtract-icon",
    text: "If you are a <developer> and want to join the crew.",
  },
  {
    icon: rocket,
    alt: "rocket-icon",
    text: "If you are a <business> and want development support.",
  },
  {
    icon: bulb,
    alt: "bulb-icon",
    text: "If you have an <idea> but can’t make it.",
  },
];
