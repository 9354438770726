import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import arrowSVG from "../../assets/images/arrow-right-new.svg";
import arrowImage1 from "../../assets/images/approach/arrow-1.svg";
import arrowImage2 from "../../assets/images/approach/arrow-2.svg";
import arrowImage3 from "../../assets/images/approach/arrow-3.svg";
import kodinImage from "../../assets/images/approach/kodin-dot.svg";

export const WhoWeAre = () => {
  return (
    <Wrapper>
      <ImageContainer>
        <ArrowKodinContainer>
          <ArrowImage1
            src={arrowImage1}
            alt="arrow-image"
            title="mask-image-1"
            width="auto"
            height="auto"
            loading="lazy"
          />
          <ArrowImage2
            src={arrowImage2}
            alt="arrow-image"
            title="mask-image-2"
            width="auto"
            height="auto"
            loading="lazy"
          />
          <ArrowImage3
            src={arrowImage3}
            alt="arrow-image"
            title="mask-image-3"
            width="auto"
            height="auto"
            loading="lazy"
          />
          <KodinImage
            src={kodinImage}
            alt="small-logo-image"
            title="logo-mask"
            width="auto"
            height="auto"
            loading="eager"
          />
        </ArrowKodinContainer>
      </ImageContainer>
      <TextContainer>
        <Title>Who we are?</Title>
        <Description>
          If you want to find out more about our mission, beliefs and team go to
          the page to see more.
        </Description>
        <Button>
          <Link to={"/about"}>
            See more{" "}
            <Icon
              src={arrowSVG}
              alt="arrow-icon"
              width="auto"
              height="auto"
              title="arrow"
              loading="lazy"
            />
          </Link>
        </Button>
      </TextContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.5fr;
  align-items: center;
  gap: 5rem;
  padding-bottom: 4rem;

  @media screen and (max-width: 1262px) {
    gap: 2rem;
  }
  @media screen and (min-width: 1550px) {
    justify-items: center;
  }
  @media screen and (max-width: 1420px) {
    grid-template-columns: 2fr 1fr;
  }
  @media screen and (max-width: 1110px) {
    display: flex;
    flex-direction: column;
  }
`;
const ArrowKodinContainer = styled.div``;
const ArrowImage1 = styled.img`
  @media screen and (max-width: 725px) {
    display: none;
  }
`;
const ArrowImage2 = styled.img`
  @media screen and (max-width: 565px) {
    display: none;
  }
`;
const ArrowImage3 = styled.img`
  @media screen and (max-width: 405px) {
    display: none;
  }
`;
const KodinImage = styled.img`
  padding-left: 4rem;
  @media screen and (max-width: 1262px) {
    padding-left: 1rem;
  }
`;
const ImageContainer = styled.div``;
const TextContainer = styled.div`
  padding-right: 6rem;

  @media screen and (max-width: 1110px) {
    width: 83%;
    margin: auto;
  }
  @media screen and (max-width: 565px) {
    width: 90%;
    padding-right: 2rem;
  }
`;
const Title = styled.h3`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: calc(46px + (48 - 28) * ((100vw - 1024px) / (1920 - 1024)));
  line-height: 60px;
  color: #1c232c;
`;
const Description = styled.p`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: calc(20px + (20 - 12) * ((100vw - 768px) / (2560 - 768)));
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #445062;
`;
const Button = styled.button`
  border: none;
  background-color: transparent;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  padding-left: 0;
  background: linear-gradient(
    86.33deg,
    #1244c5 0.53%,
    #225aeb 50.08%,
    #2490f4 96.62%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-top: 1rem;
`;
const Icon = styled.img``;
