import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import styled from "@emotion/styled";
import Carousel from "../layout-elements/carousel/Carousel.component";
import ClientCard from "../layout-elements/ClientCard.component";
import spacefluxLogo from "../../assets/images/clients/spaceflux.png";
import telescopeLiveLogo from "../../assets/images/clients/telescope-live.png";
import greenBoxLogo from "../../assets/images/clients/green-box.png";
import ffwLogo from "../../assets/images/clients/ffw.svg";

interface Props {
  pt?: string;
}

const Testimonial = ({ pt }: Props) => {
  // const testimonials = useSelector(
  //   (state: RootState) => state.service.testimonials
  // );

  return (
    <TestimonialWrapper style={{ paddingTop: pt }}>
      {/* <Carousel>
        {testimonials.map((el, index) => (
          <Item key={index} {...el} />
        ))}
      </Carousel> */}

      <InnerContainer>
        <Title>&lt;Some of our clients&gt;</Title>
        <CardContainer>
          {clientData.map((el, index) => (
            <ClientCard
              key={index}
              logo={el.companyLogo}
              company={el.company}
              website={el.website}
              alt={el.alt}
            />
          ))}
        </CardContainer>
      </InnerContainer>
    </TestimonialWrapper>
  );
};

export default Testimonial;

const TestimonialWrapper = styled.div`
  /* padding-bottom: 5rem; - this padding is for the wrapper and has to be uncomment with the Carousel  */
  background: linear-gradient(180deg, #eff2f8 0%, rgba(255, 255, 255, 0) 100%);
  @media screen and (max-width: 1024px) {
    padding-bottom: 3.75rem;
  }
`;

const InnerContainer = styled.div`
  width: 83%;
  margin: auto;
  /* padding-top: 5rem; */
  padding-top: 0rem;
`;

const Title = styled.h2`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 3.75rem;
  letter-spacing: 0.2px;
  color: #1c232c;
  padding-bottom: 4.5rem;
`;

const Item = styled.div<{
  img: string;
  review: string;
  name: string;
  company: string;
}>`
  text-align: center;
  padding: 6.25rem;
  background-image: ${(props) => `url(${props.img})`};
  background-size: cover;
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 3.25rem;
  @media screen and (max-width: 835px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0;
  }
  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const clientData = [
  {
    companyLogo: spacefluxLogo,
    company: "Spaceflux",
    website: "https://spaceflux.io",
    alt: "spaceflux-logo",
  },
  {
    companyLogo: telescopeLiveLogo,
    company: "Telescope Live",
    website: "https://telescope.live",
    alt: "telescope-live-logo",
  },
  {
    companyLogo: greenBoxLogo,
    company: "Green Team",
    website: "https://greenteamworldwide.com",
    alt: "green-box-logo",
  },
  {
    companyLogo: ffwLogo,
    company: "FFW",
    website: "https://ffw.com",
    alt: "ffw-logo",
  },
];
