import React from "react";
import useMobileScreen from "../../hooks/useMobileScreen";
import { MOBILE_WIDTH_SCREEN } from "../../utils";
import { ExpertiseCard } from "../../components/layout-elements/cards/ExpertiseCard.component";
import { expertiseCardData } from "../../store/storeAllData";
import { OfferSection } from "../../components/proposal/ProposalSection.component";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

const CloudNativeDevelopmentPage = () => {
  const isMobile = useMobileScreen(MOBILE_WIDTH_SCREEN);

  return (
    <>
      <Helmet>
        <title>Cloud native development - Kodin Soft</title>
        <meta
          name="description"
          content="Cloud-native development embraces cloud computing, using its services & tech for rapid, scalable app building, deployment, and running."
        />
        <link rel="canonical" href="https://www.kodinsoft.net/expertise/cloud-native-development" />
      </Helmet>
      <Wrapper>
        <ExpertiseCard
          img={expertiseCardData.cloud.image}
          alt={expertiseCardData.cloud.alt}
          title={expertiseCardData.cloud.title}
          description={expertiseCardData.cloud.description}
          content={expertiseCardData.cloud.content}
          techStack={expertiseCardData.cloud.techStack}
        />
        <OfferSection isMobile={isMobile} />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 83%;
  margin: auto;
`;

export default CloudNativeDevelopmentPage;
