import styled from "@emotion/styled";
import React from "react";
import { useNavigate } from "react-router-dom";
import backArrow from "../assets/images/projects/back-arrow.svg";

interface ParentProps {
  text: string;
  path: string;
  style?: React.CSSProperties;
}

const BackButton = ({ text, path, style }: ParentProps) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(path);
  };
  return (
    <Button onClick={handleNavigation} style={style}>
      <Icon
        src={backArrow}
        alt="back-arrow"
        title="back-arrow"
        width="18px"
        height="18px"
        loading="eager"
      />
      {text}
    </Button>
  );
};

const Button = styled.button`
  color: black;
  font-family: "Outfit";
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1rem;
`;
const Icon = styled.img``;

export default BackButton;
