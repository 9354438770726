import React from "react";
import styled from "@emotion/styled";

import zagoraDevImage from "../../assets/images/zagora-dev-photo.jpg";
import teamImage from "../../assets/images/team-image.png";
import circlesImag from "../../assets/images/bottom-circles1.svg";

const Opportunity = () => {
  return (
    <Wrapper>
      <ImageContainer>
        <Circles
          src={circlesImag}
          alt="circles-image"
          title="circles-image-mask"
          width="auto"
          height="auto"
          loading="lazy"
        />
        <Papper>
          <Image
            src={zagoraDevImage}
            alt="zagora-dev"
            title="cover-image"
            width="auto"
            height="auto"
            loading="eager"
          />
          <CircleMask>
            <CircleImage
              src={teamImage}
              alt="team-image"
              title="circle-cover-image"
              width="auto"
              height="auto"
              loading="eager"
            />
          </CircleMask>
        </Papper>
      </ImageContainer>
      <TextContainer>
        <Title>We don't miss an opportunity to &lt;build&gt; the team.</Title>
        <Description>
          We are software experts that are ready to boost your development.
        </Description>
      </TextContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 9rem;
  gap: 3rem;

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    padding-bottom: 7rem;
  }

  /* @media screen and (min-width: 1750px) {
    justify-content: space-around;
  } */
`;

const ImageContainer = styled.div`
  position: relative;
  width: 48%;
  @media screen and (max-width: 1024px) {
    width: 90%;
  }
  @media screen and (min-width: 1750px) {
    width: 35.5%;
  }
`;

const Papper = styled.div`
  background: #ffffff;
  box-shadow: 0px 24px 72px rgba(21, 24, 28, 0.15);
  backdrop-filter: blur(12px);
  position: relative;
  z-index: 10;

  @media screen and (min-width: 1750px) {
    width: 100%;
  }
`;
const Image = styled.img`
  padding: 3.125rem 3rem;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1024px) {
    padding: 1.125rem 1rem;
  }
`;
const Circles = styled.img`
  position: absolute;
  padding: 3.125rem 3rem;
  z-index: 1;
  bottom: -150px;
  right: -150px;

  @media screen and (max-width: 1024px) {
    bottom: -12rem;
    right: -6rem;
    max-width: 220px;
    display: none;
  }

  @media screen and (min-width: 1024px) and (max-width: 1069px) {
    bottom: -24%;
  }

  @media screen and (min-width: 1069px) and (max-width: 1100px) {
    bottom: -28%;
  }
`;

const CircleMask = styled.div`
  position: absolute;
  padding: 2.25rem;
  background: rgba(248, 249, 249, 0.48);
  bottom: -2.5rem;
  right: -3rem;
  border-radius: 50%;
  border: 2px solid rgba(207, 213, 223, 1);
  backdrop-filter: blur(12px);
  box-shadow: 0px 24px 72px rgba(21, 24, 28, 0.08);
  mix-blend-mode: luminosity;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    bottom: -3.5rem;
    right: -1rem;
    padding: 0.125rem;
    width: 150px;
    height: 150px;
  }
`;

const CircleImage = styled.img`
  mix-blend-mode: luminosity;
  display: flex;
  @media screen and (max-width: 1024px) {
    width: 85%;
    max-width: 160px;
    max-height: 160px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 41%;
  z-index: 12;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const Title = styled.h3`
  font-family: "Outfit";
  font-size: 3rem;
  line-height: 3.75rem;
  color: #1c232c;
  @media screen and (min-width: 1750px) {
    font-size: calc(60px + 3 * ((100vw - 768px) / (2560 - 768)));
    line-height: 4.75rem;
  }
`;

const Description = styled.p`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.625rem;
  color: #445062;
  @media screen and (min-width: 1750px) {
    font-size: calc(20px + (20 - 16) * ((100vw - 768px) / (2560 - 768)));
    line-height: 2rem;
  }
`;

export default Opportunity;
