import React from "react";
import styled from "@emotion/styled";

import worldImage from "../../assets/images/world-background-dotted.svg";
import kodinLocationImage from "../../assets/images/kodin-square-marker.png";
import { MOBILE_WIDTH_SCREEN } from "../../utils";
import useMobileScreen from "../../hooks/useMobileScreen";

const OurVision = () => {
  const isMobile = useMobileScreen(MOBILE_WIDTH_SCREEN);

  return (
    <Wrapper>
      <TextContainer>
        <Title>We are 100% &lt;remote&gt; company</Title>
        <Description>
          We have clients all around the world. We do not believe in borders.
        </Description>
      </TextContainer>
      <ImageContainer>
        {!isMobile && <BackgroundMask />}
        <WorldImage
          src={worldImage}
          alt="world-map"
          title="world-map-image"
          width="auto"
          height="auto"
          loading="eager"
        />
        <KodinLocationImage
          src={kodinLocationImage}
          alt="kodin-location-marker"
          title="kodin-location-marker"
          width="auto"
          height="auto"
          loading="lazy"
        />
      </ImageContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  height: 31.875rem;
  margin-top: 4rem;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  width: 41%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const Title = styled.h3`
  font-family: "Outfit";
  font-size: 3rem;
  line-height: 3.75rem;
  color: #1c232c;
  @media screen and (max-width: 1024px) {
    font-size: calc(36px + (36 - 18) * ((100vw - 375px) / (1024 - 375)));
  }
  @media screen and (min-width: 1750px) {
    font-size: calc(60px + 3 * ((100vw - 768px) / (2560 - 768)));
    line-height: 4.75rem;
  }
`;

const Description = styled.p`
  font-family: "Outfit";
  font-size: 1.25rem;
  line-height: 1.625rem;
  color: #445062;
  @media screen and (max-width: 1024px) {
    font-size: calc(20px + (20 - 16) * ((100vw - 375px) / (1024 - 375)));
  }
  @media screen and (min-width: 1750px) {
    font-size: calc(20px + (20 - 16) * ((100vw - 768px) / (2560 - 768)));
    line-height: 2rem;
  }
`;

const ImageContainer = styled.div``;

const BackgroundMask = styled.div`
  height: 31.875rem;
  width: 10rem;
  position: absolute;
  right: 0;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(255, 255, 255, 1)
  );
  z-index: 10;
`;

const WorldImage = styled.img`
  position: absolute;

  @media screen and (max-width: 1024px) {
    width: 120%;
    object-fit: cover;
    left: -50px;
  }
  @media screen and (min-width: 1440px) {
    right: 0;
  }
`;
const KodinLocationImage = styled.img`
  position: absolute;
  width: 15rem;
  height: 15rem;
  right: 10rem;
  margin-top: 5.75rem;

  @media screen and (max-width: 1024px) {
    width: 9.375rem;
    height: 9.375rem;
    right: 4rem;
    margin-top: 2.75rem;
  }
`;

export default OurVision;
