import React from "react";
import { ExpertiseCard } from "../../components/layout-elements/cards/ExpertiseCard.component";
import useMobileScreen from "../../hooks/useMobileScreen";
import { MOBILE_WIDTH_SCREEN } from "../../utils";
import { expertiseCardData } from "../../store/storeAllData";
import { OfferSection } from "../../components/proposal/ProposalSection.component";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

const MobileDevelopmentPage = () => {
  const isMobile = useMobileScreen(MOBILE_WIDTH_SCREEN);

  return (
    <>
      <Helmet>
        <title>Mobile development - Kodin Soft</title>
        <meta
          name="description"
          content="Mobile app solutions for all platforms. Our expertise enables captivating apps for any device, fascinating customers regardless of type."
        />
        <link rel="canonical" href="https://www.kodinsoft.net/expertise/mobile-development" />
      </Helmet>
      <Wrapper>
        <ExpertiseCard
          img={expertiseCardData.mobile.image}
          alt={expertiseCardData.mobile.alt}
          title={expertiseCardData.mobile.title}
          description={expertiseCardData.mobile.description}
          content={expertiseCardData.mobile.content}
        />
        <OfferSection isMobile={isMobile} />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 83%;
  margin: auto;
`;

export default MobileDevelopmentPage;
