import React from "react";

import Hero from "../components/header/navigation/hero/Hero.component";
import Projects from "../components/projects/Projects.component";
import ProposalSection from "../components/proposal/ProposalSection.component";
import Testimonial from "../components/testimonial/Testimonial.component";
import { Helmet } from "react-helmet-async";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Home - Kodin Soft</title>
        <meta
          name="description"
          content="Designing and Developing software solutions"
        />
        <link rel="canonical" href="https://www.kodinsoft.net/" />
      </Helmet>
      <Hero />
      <ProposalSection />
      <Projects />
      <Testimonial />
    </>
  );
};

export default HomePage;
