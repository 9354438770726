import React from "react";
import styled from "@emotion/styled";
import { Paragraph, Title } from "./styles";
import { FormattedText } from "../layout-elements/FormattedText";

const AdditionalSections = ({ additionalSections }: any) => {
  return (
    <>
      {additionalSections.map((el: any, index: any) => (
        <React.Fragment key={index}>
          <Container>
            <Title as="h2">{el.title}</Title>
            {el.content.map((text: string, index: any) => (
              <React.Fragment key={index}>
                <Paragraph>
                  <FormattedText text={text} />
                </Paragraph>
                <br />
              </React.Fragment>
            ))}
          </Container>
        </React.Fragment>
      ))}
    </>
  );
};

const Container = styled.div``;

export default AdditionalSections;
