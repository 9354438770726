import styled from "@emotion/styled";
import TextWithTitle from "../TextWithTitle.component";
import TechnologyStack from "../TechnologyStack.component";
import BackButton from "../../BackButton.component";
import { ITechStackItem } from "../../../model/interfaces/projects/IProjectData";

const customCardTitleStyle = {
  title: {
    fontSize: "calc(44px + (44 - 36) * ((100vw - 1024px) / (1920 - 1024)))",
    lineHeight: "60px",
  },
  description: {
    fontSze: "calc(18px + (18 - 14) * ((100vw - 1024px) / (1920 - 1024)))",
    lineHeight: "28px",
    width: "100%",
  },
  container: {
    paddingBottom: "72px",
  },
};

const customContentTitleStyle = {
  title: {
    fontSize: "34px",
    lineHeight: "48px",
  },
  description: {
    fontSize: "calc(14px + (14 - 10) * ((100vw - 1024px) / (1920 - 1024)))",
    lineHeight: "22px",
    width: "95%",
  },
  container: {
    paddingBottom: "36px",
  },
};

interface CardProps {
  img: string;
  alt: string;
  title: string;
  description: string;
  content: ContentProps[];
  techStack?: ITechStackItem[];
}

interface ContentProps {
  title: string;
  description: string;
  customStyles?: any;
  techStack?: ITechStackItem[];
}

export const ExpertiseCard = ({
  img,
  alt,
  title,
  description,
  content,
  techStack,
}: CardProps) => {
  return (
    <Wrapper>
      <BackButton
        text="Expertise"
        path="/expertise"
        style={{ marginTop: "-50px", marginBottom: "30px" }}
      />
      <Image
        src={img}
        alt={alt}
        title={alt}
        width="auto"
        height="auto"
        loading="eager"
      />
      <TextWithTitle
        title={title}
        description={description}
        showButton={false}
        customStyles={customCardTitleStyle}
        type="h1"
      />
      {content.map((el: ContentProps, i: number) => (
        <ContentContainer key={i}>
          <TextWithTitle
            key={i}
            title={el.title}
            description={el.description}
            showButton={false}
            customStyles={customContentTitleStyle}
          />
          {el.techStack && (
            <div style={{ display: "flex" }}>
              {el.techStack?.map((el: ITechStackItem, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TechImage
                    src={el.icon}
                    alt={el.alt}
                    title={el.alt}
                    loading="lazy"
                    width="100"
                    height="100"
                  />
                  <TechName>{el.name}</TechName>
                </div>
              ))}
            </div>
          )}
        </ContentContainer>
      ))}
      {techStack && <TechnologyStack stack={techStack} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 8.75rem;
  padding-bottom: 5rem;

  &:last-child {
    padding-bottom: 10.625rem;
  }
`;

const Image = styled.img`
  padding-bottom: 2.25rem;
`;

const ContentContainer = styled.div`
  &:nth-of-type(3) {
    padding-top: 4rem;
  }
`;

const TechImage = styled.img`
  width: 100px;
`;

const TechName = styled.p`
  white-space: nowrap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: -1rem;
  color: black;
`;
