import styled from "@emotion/styled";

export const MaskCirclesImages = () => {
  return (
    <>
      <Mask1 />
      <Mask3 />
      <Mask2 />
    </>
  );
};

const Mask1 = styled.div`
  width: 450px;
  height: 450px;
  border-radius: 50%;
  border: 2px solid rgba(226, 235, 247, 1);
  position: absolute;
  top: -50px;
  left: -130px;
  background: rgba(248, 249, 249, 0.48);
  opacity: 0.05;
  box-shadow: 0px 24px 72px rgba(21, 24, 28, 0.08);
  backdrop-filter: blur(12px);
`;
const Mask2 = styled.div`
  width: 800px;
  height: 800px;
  border-radius: 50%;
  position: absolute;
  top: -220px;
  left: -300px;
  background: rgba(248, 249, 249, 0.48);
  opacity: 0.05;
  box-shadow: 0px 24px 72px rgba(21, 24, 28, 0.08);
  backdrop-filter: blur(12px);
`;
const Mask3 = styled.div`
  width: 950px;
  height: 950px;
  border-radius: 50%;
  position: absolute;
  top: -270px;
  left: -330px;
  background: #f2f5f9;
  opacity: 0.02;
  box-shadow: 0px 24px 72px rgba(21, 24, 28, 0.08);
  backdrop-filter: blur(12px);
`;
