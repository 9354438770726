import React, { Fragment } from "react";

export const FormattedText = ({ text }: { text: string }): JSX.Element => {
  if (!text) return <></>;

  const processedText = text.replace(/\\n/g, "\n");

  const lines = processedText.split("\n");

  const processLine = (line: string) => {
    if (!line.includes("**")) return line;

    const parts = line.split(/(\*\*.*?\*\*)/g);
    return parts.map((part, index) => {
      if (part.startsWith("**") && part.endsWith("**")) {
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      }
      return <span key={index}>{part}</span>;
    });
  };

  return (
    <>
      {lines.map((line, idx) => (
        <Fragment key={idx}>
          {processLine(line)}
          {idx < lines.length - 1 && <br />}
        </Fragment>
      ))}
    </>
  );
};
