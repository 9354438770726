import React from "react";
import styled from "@emotion/styled";

import ProposalSection from "../components/proposal/ProposalSection.component";
import TextWithTitle from "../components/layout-elements/TextWithTitle.component";
import ProjectCard from "../components/projects/ProjectCard.component";
import { cardProjectData } from "../store/storeAllData";
import { Helmet } from "react-helmet-async";

const customTitleStyle = {
  title: {
    fontSize: "60px",
    lineHeight: "72px",
  },
  description: {
    fontSize: "24px",
    lineHeight: "34px",
    width: "100%",
  },
  container: {
    paddingTop: "60px",
    paddingBottom: "60px",
  },
};

const ProjectsPage = () => {
  return (
    <Wrapper>
      <Helmet>
        <title>Projects - Kodin Soft</title>
        <meta
          name="description"
          content="Powerful code in action: exploring our software development case studies."
        />
        <link rel="canonical" href="https://www.kodinsoft.net/projects" />
      </Helmet>
      <Container>
        <TextWithTitle
          title="Case Studies"
          description="Powerful code in action: exploring our software development case studies"
          showButton={false}
          customStyles={customTitleStyle}
          type="h1"
        />
        <InnerContainer>
          {cardProjectData.map((el, index) => (
            <ProjectCard
              key={index}
              title={el.title}
              image={el.img}
              alt={el.alt}
              intro={el.content.intro}
              url={el.url}
              reverse={index % 2 === 1}
              type="h2"
              stack={el.stack}
            />
          ))}
        </InnerContainer>
      </Container>
      <ProposalSection
        gradient={"linear-gradient(0deg, #E9F1FC 0%, #F8F9F9 100%)"}
      />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  padding-top: 3rem;
  background: #eef2f9;
`;
const Container = styled.div`
  width: 83%;
  margin: auto;

  @media screen and (max-width: 1024px) {
    width: 89%;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1550px) {
    align-items: center;
  }
`;

export default ProjectsPage;
