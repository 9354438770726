import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import ArrowSvg from "../../../SVG/ArrowSvg";

export const HeroTitle = () => {
  return (
    <Wrapper>
      <Title>Designing and Developing software solutions</Title>
      <Button>
        <NavLink to="/approach">
          See our approach <ArrowSvg color="#ffffff" />
        </NavLink>
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 75%;
  justify-self: end;
  margin-top: 15%;
  @media screen and (max-width: 1220px) {
    justify-self: center;
  }

  @media screen and (max-width: 860px) {
    margin-top: 6rem;
  }
`;

const Title = styled.h1`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: calc(60px + (60 - 56) * ((100vw - 1024px) / (1920 - 1024)));
  line-height: 72px;
  color: white;
`;

const Button = styled.button`
  margin: 2.5rem 1rem 1rem 0;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #1c232c;
  border: none;
  background-color: transparent;
`;

const NavLink = styled(Link)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  text-decoration: none;
  gap: 10px;
  background: linear-gradient(86.33deg, #FFFFFF 0.53%, #FFFFFF 50.08%, #FFFFFF 96.62%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 10;
  &:hover {
    color: #1c232c;
  }
`;