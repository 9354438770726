// ImplementationSection.component.js
import React from "react";
import styled from "@emotion/styled";
import { BulletTitle, DashedContainer, Paragraph } from "./styles";
import { IImplementation } from "../../model/interfaces/projects/IProjectData";

const ImplementationSection = ({
  title,
  subtitle,
  intro,
  sections,
  outro,
}: IImplementation) => {
  return (
    <>
      <Title as="h2">{title}</Title>
      <Subtitle as="h3">{subtitle}</Subtitle>
      {intro && (
        <SecondaryContainer>
          {intro.map((el, index) => (
            <React.Fragment key={index}>
              <Paragraph>{el}</Paragraph>
              <br />
            </React.Fragment>
          ))}
        </SecondaryContainer>
      )}
      <SecondaryContainer>
        {sections.map((el, index) => (
          <React.Fragment key={index}>
            {index !== sections.length - 1 ? (
              <DashedContainer>
                <BulletTitle>{el.title}</BulletTitle>
                <Paragraph>{el.content}</Paragraph>
              </DashedContainer>
            ) : (
              <div style={{ paddingLeft: "44px", marginTop: "-35px" }}>
                <BulletTitle>{el.title}</BulletTitle>
                <Paragraph>{el.content}</Paragraph>
              </div>
            )}
          </React.Fragment>
        ))}
      </SecondaryContainer>
      {outro && (
        <React.Fragment>
          <br />
          <SecondaryContainer>
            <Paragraph>{outro}</Paragraph>
          </SecondaryContainer>
        </React.Fragment>
      )}
    </>
  );
};

const Title = styled.h1`
  color: #1c232c;
  font-size: calc(30px + (60 - 30) * ((100vw - 375px) / (1920 - 375)));
  font-family: "Outfit";
`;

const Subtitle = styled.h2`
  color: #1c232c;
  font-size: calc(30px + (48 - 30) * ((100vw - 375px) / (1920 - 375)));
  font-family: "Outfit";
`;

const SecondaryContainer = styled.div``;

export default ImplementationSection;
