import React from "react";
import styled from "@emotion/styled";

import { ITechStackItem } from "../../model/interfaces/projects/IProjectData";

interface ParentProps {
  stack: ITechStackItem[];
}

const TechnologyStack = ({ stack }: ParentProps) => {
  return (
    <div style={{ display: "flex" }}>
      {stack.map((el: ITechStackItem, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TechImage
            src={el.icon}
            alt={el.alt}
            title={el.alt}
            loading="lazy"
            width="100"
            height="100"
          />
          <TechName>{el.name}</TechName>
        </div>
      ))}
    </div>
  );
};

export default TechnologyStack;

const TechImage = styled.img`
  width: 100px;
`;

const TechName = styled.p`
  white-space: nowrap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: -1rem;
  color: black;
`;
