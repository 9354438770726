import React from "react";
import styled from "@emotion/styled";
import ProjectComponent from "../components/projects/simple-project-layout/ProjectComponent.component";
import Testimonial from "../components/testimonial/Testimonial.component";
import TextWithTitle from "../components/layout-elements/TextWithTitle.component";

import BiographyContainer from "../components/layout-elements/BiographyContainer.component";
import OurVision from "../components/layout-elements/OurVision.component";

import Worldview from "../components/layout-elements/Worldview.component";
import Opportunity from "../components/layout-elements/Opportunity.component";
import { MOBILE_WIDTH_SCREEN } from "../utils";
import useMobileScreen from "../hooks/useMobileScreen";
import { Helmet } from "react-helmet-async";

const customTitleStyles = {
  title: {
    fontSize: "60px",
    lineHeight: "72px",
  },
  description: {
    fontSize: "24px",
    lineHeight: "34px",
    width: "100%",
  },
  container: {
    paddingTop: "88px",
    paddingBottom: "88px",
  },
};

const AboutPage = () => {
  const isMobile = useMobileScreen(MOBILE_WIDTH_SCREEN);

  if (isMobile) {
    customTitleStyles.container = {
      paddingTop: "50px",
      paddingBottom: "50px",
    };
    customTitleStyles.title = {
      fontSize: "40px",
      lineHeight: "52px",
    };
  }
  return (
    <Wrapper>
      <Helmet>
        <title>We are Kodin Soft</title>
        <meta
          name="description"
          content="Software company focused in designing and developing high tech solutions"
        />
        <link rel="canonical" href="https://www.kodinsoft.net/about" />
      </Helmet>
      <Container>
        <TextWithTitle
          title="We are Kodin Soft"
          description="Software company focused in designing and developing high tech solutions"
          showButton={false}
          customStyles={customTitleStyles}
          type="h1"
        />
        <Worldview />
        <BiographyContainer />
        <OurVision />
        <Opportunity />
      </Container>

      <ProjectComponent />

      <Testimonial pt="5rem" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 3rem;
  background: #f8f9f9;
`;

const Container = styled.div`
  width: 70%;
  margin: auto;

  @media screen and (max-width: 1024px) {
    width: 89%;
  }
`;

export default AboutPage;
