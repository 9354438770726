// TechStackSection.component.js
import React from 'react';
import styled from '@emotion/styled';
import { Subtitle } from './styles';
import { ITechStack, ITechStackItem } from '../../model/interfaces/projects/IProjectData';

interface ParentProps {
	techStack: ITechStack;
}

const TechStackSection = ({ techStack }: ParentProps) => {
	const getTechStackCloudText = () => {
		if (techStack.aws) return 'AWS:';
		if (techStack.azure) return 'Azure:';
	};

	const techStackCloudArray = techStack.azure ?? techStack.aws ?? [];

	return (
		<Wrapper
			style={{
				backgroundColor: techStack.backgroundColor,
				gridTemplateColumns: techStack.backgroundImage ? '3fr 1fr' : 'auto',
				...techStack.imageStyles,
			}}
		>
			<div>
				<Subtitle style={{ color: 'white' }}>Technology stack we use</Subtitle>
				<StackContainer>
					{techStack.stack.map((el: ITechStackItem, index) => (
						<div
							key={index}
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<TechImage
								src={el.icon}
								alt={el.alt}
								title={el.alt}
								loading='lazy'
								width='100'
								height='100'
							/>
							<TechName>{el.name}</TechName>
						</div>
					))}
				</StackContainer>

				<TechName style={{ marginTop: '1.5rem', fontSize: '22px' }}>{getTechStackCloudText()}</TechName>
				<StackContainer>
					{techStackCloudArray?.map((el: ITechStackItem, index) => (
						<div
							key={index}
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<TechImage
								src={el.icon}
								alt={el.alt}
								title={el.alt}
								loading='lazy'
								width='100'
								height='100'
							/>
							<TechName>{el.name}</TechName>
						</div>
					))}
				</StackContainer>
			</div>
			{techStack.backgroundImage && (
				<ImageWrapper>
					<BackgroundImage
						src={techStack.backgroundImage}
						alt='cover-image'
						title='cover-image'
						loading='eager'
						css={techStack.imageStyles}
						width='100%'
						height='auto'
					/>
				</ImageWrapper>
			)}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: grid;
	align-items: center;
	justify-items: center;
	margin-bottom: 2rem;

	@media screen and (max-width: 800px) {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column-reverse;
		align-items: center;
		justify-content: ${(props) => (props.style && props.style.backgroundImage ? 'center' : 'initial')};
	}
`;

const StackContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 2rem;
`;

const TechImage = styled.img`
	width: 100px;
`;

const TechName = styled.p`
	white-space: nowrap;
	font-family: Outfit;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	margin-top: -1rem;
	color: white;
`;

const ImageWrapper = styled.div`
	@media screen and (max-width: 800px) {
		display: flex;
		justify-content: center;
		margin-top: -95px;
	}
	@media screen and (max-width: 535px) {
		margin-top: -85px;
		margin-top: -17%;
	}
`;

const BackgroundImage = styled.img<{ css?: any }>`
	width: 100%;
	@media screen and (max-width: 800px) {
		width: 50%;
		transform: rotate(270deg);
		${(props) => props.css}
	}
`;

export default TechStackSection;
