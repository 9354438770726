import React from "react";
import styled from "@emotion/styled";

import extendedTeamIcon from "../../assets/images/icons/extended-team.svg";
import managedTeamIcon from "../../assets/images/icons/managed-team.svg";
import reactIcon from "../../assets/images/icons/react.svg";
import TextWithTitle from "../layout-elements/TextWithTitle.component";
import CardWithIcon from "../layout-elements/CardWithIcon.component";
import { Link } from "react-router-dom";
import arrowSVG from "../../assets/images/arrow-right-new.svg";
import { MOBILE_WIDTH_SCREEN } from "../../utils";
import useMobileScreen from "../../hooks/useMobileScreen";
import { cardsData } from "../../store/storeAllData";

type Props = {
  gradient?: string;
};

interface SectionProps {
  isMobile: boolean;
  customStyles?: any;
}

export const DoSection = ({ isMobile, customStyles }: SectionProps) => (
  <FirstSection style={customStyles ? customStyles : null}>
    <TextWithTitle
      title={descriptionsData[0].title}
      description={descriptionsData[0].description}
      linkTo={descriptionsData[0].link}
    />
    <CardsWrapper>
      {cardsData.map((item, i) => (
        <CardWithIcon
          key={i}
          title={item.title}
          icon={item.icon}
          alt={item.alt}
          style={{ iconWidth: "initial" }}
          link={item.link}
        />
      ))}
    </CardsWrapper>
    {isMobile && (
      <Button>
        <Link to={descriptionsData[0].link}>
          See more{" "}
          <Icon
            src={arrowSVG}
            alt="arrow-icon"
            width="auto"
            height="auto"
            title="arrow"
            loading="lazy"
          />
        </Link>
      </Button>
    )}
  </FirstSection>
);

export const OfferSection = ({ isMobile }: SectionProps) => (
  <OfferSectionWrapper>
    <TextWithTitle
      title={descriptionsData[1].title}
      description={descriptionsData[1].description}
      linkTo={descriptionsData[1].link}
    />
    <CardsWrapper>
      {cardsDataWithIcon.map((item, i) => (
        <CardWithIcon
          key={i}
          title={item.title}
          icon={item.icon}
          description={item.description}
          style={item.style}
          alt={item.alt}
          link={`/services/#${item.id}`}
        />
      ))}
    </CardsWrapper>
    {isMobile && (
      <Button>
        <Link to={descriptionsData[1].link}>
          See more <Icon src={arrowSVG} alt="arrow-icon" />
        </Link>
      </Button>
    )}
  </OfferSectionWrapper>
);

const ProposalSection = ({ gradient }: Props) => {
  const isMobile = useMobileScreen(MOBILE_WIDTH_SCREEN);

  return (
    <Wrapper style={{ background: gradient }}>
      <Container>
        <DoSection isMobile={isMobile} />
        <OfferSection isMobile={isMobile} />
      </Container>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  font-family: "Outfit";
  width: 100%;
  background: linear-gradient(180deg, #e2ebf8 0%, rgba(255, 255, 255, 0) 100%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &:last-of-type {
    justify-content: center;
  }
`;
const Container = styled.div`
  width: 83%;
`;

const FirstSection = styled.div`
  padding-top: 5rem;
  padding-bottom: 5rem;

  @media screen and (max-width: 1024px) {
    padding-bottom: 4.5rem;
    padding-top: 4rem;
  }
`;
const OfferSectionWrapper = styled.div`
  padding-bottom: 5rem;

  @media screen and (max-width: 1024px) {
    padding-bottom: 4rem;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 530px) {
    flex-direction: column;
  }
  @media screen and (max-width: 1300px) {
    flex-wrap: wrap;
  }
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.375rem;
  z-index: 12;

  background: linear-gradient(
    86.33deg,
    #1244c5 0.53%,
    #225aeb 50.08%,
    #2490f4 96.62%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  @media screen and (max-width: 1024px) {
    margin-top: 3rem;
  }
  @media screen and (min-width: 1920px) {
    font-size: calc(20px + (20 - 16) * ((100vw - 1440px) / (1920 - 1440)));
  }
`;

const Icon = styled.img``;

export default ProposalSection;

const customStyle = {
  width: "30.85%",
  maxHeight: "376px",
  height: "auto",
  titleWidth: "100%",
  fontSize: "calc(34px + (34 - 30) * ((100vw - 1440px) / (1920 - 1440)))",
};

export const cardsDataWithIcon = [
  {
    icon: reactIcon,
    title: "Product development",
    description: "We take care of the process, you enjoy the results...",
    style: customStyle,
    alt: "React",
    id: "product-development",
  },
  {
    icon: managedTeamIcon,
    title: "Managed team",
    description: "We build complete team ready to work on your projects...",
    style: customStyle,
    alt: "Manage",
    id: "managed-team",
  },
  {
    icon: extendedTeamIcon,
    title: "Extended team",
    description: "We provide you extra pair of hands for your in-house team...",
    style: customStyle,
    alt: "Team",
    id: "extended-team",
  },
];

const descriptionsData = [
  {
    title: "<What we do>",
    description: "Full-stack software development for end-to-end solutions",
    link: "/expertise",
  },
  {
    title: "<What we offer>",
    description: "We offer different engagement models",
    link: "/services",
  },
];
