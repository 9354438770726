import styled from "@emotion/styled";
import { HeroTitle } from "../components/approach/HeroTitle.component";
import { HeroImage } from "../components/approach/HeroImage.component";
import { MaskCirclesImages } from "../components/approach/MaskCircles.component";
import { ImageTextCard } from "../components/approach/ImageTextCard.component";
import requirementsImage from "../assets/images/approach/requirements.jpg";
import choosingStackImage from "../assets/images/approach/choosing-stack.jpg";
import designImage from "../assets/images/approach/design-architecture.jpg";
import agileImage from "../assets/images/approach/agile-methodology.jpg";
import qualityImage from "../assets/images/approach/quality-and-testing.jpg";
import continuousImage from "../assets/images/approach/continuous-deployment.jpg";
import { WhoWeAre } from "../components/approach/WhoWeAre.component";
import { Helmet } from "react-helmet-async";

const cardsData = [
  {
    image: requirementsImage,
    alt: "requirements-image",
    title: "Understanding client requirements",
    description:
      "The first step in designing and developing software solutions is to clearly understand the client's requirements. This includes not only the functional requirements of the software, but also the non-functional requirements such as performance, security, and scalability.",
    position: {
      left: "-6rem",
      bottom: "-6rem",
    },
  },
  {
    image: choosingStackImage,
    alt: "choosing-image",
    title: "Choosing the right technology stack",
    description:
      "Once the requirements are understood, it is important to choose the right technology stack based on the project's needs. This includes selecting the programming language, database, and other software tools required for the project",
    position: {
      right: "-6rem",
      top: "-6rem",
    },
  },
  {
    image: designImage,
    alt: "design-image",
    title: "Designing the software architecture",
    description:
      "The software architecture is the foundation of the software solution, and it is important to design it in a way that meets the project's requirements and enables scalability and maintainability.",
    position: {
      left: "-6rem",
      bottom: "-6rem",
    },
  },
  {
    image: agileImage,
    alt: "agile-image",
    title: "Agile development methodology",
    description:
      "Agile development methodologies such as Scrum and Kanban are commonly used in software development to enable rapid iteration, continuous feedback, and efficient delivery of high-quality software products.",
    position: {
      right: "-6rem",
      top: "-6rem",
    },
  },
  {
    image: qualityImage,
    alt: "quality-image",
    title: "Quality assurance and testing",
    description:
      "Quality assurance and testing are crucial steps in software development to ensure that the software product is reliable, secure, and performs as expected.",
    position: {
      left: "-6rem",
      top: "-6rem",
    },
  },
  {
    image: continuousImage,
    alt: "continuous-image",
    title: "Continuous deployment and delivery",
    description:
      "Continuous deployment and delivery processes enable the software to be delivered to the client in a timely and efficient manner, with updates and improvements made on an ongoing basis.",
    position: {
      right: "-6rem",
      top: "-6rem",
    },
  },
];
const ApproachPage = () => {
  return (
    <Wrapper>
      <Helmet>
        <title>Our approach - Kodin Soft</title>
        <meta
          name="description"
          content="Designing and developing software solutions requires a structured and disciplined approach to ensure that the final product meets the requirements of the client."
        />
        <link rel="canonical" href="https://www.kodinsoft.net/approach" />
      </Helmet>
      <MaskCirclesImages />
      <HeroContainer>
        <HeroTitle />
        <HeroImage />
      </HeroContainer>
      {cardsData.map((el, i) => (
        <ImageTextCard
          key={i}
          reverse={i % 2 === 1}
          img={el.image}
          alt={el.alt}
          title={el.title}
          description={el.description}
          position={el.position}
        />
      ))}
      <WhoWeAre />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #f8f9f9;
`;

const HeroContainer = styled.div`
  height: 43.75rem;
  background: linear-gradient(
    83.27deg,
    #1244c5 8.43%,
    #225aeb 58.86%,
    #2490f4 106.22%
  );
  grid-template-columns: 1fr 1fr;
  display: grid;
  align-items: center;
  margin-bottom: 3rem;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 2fr 1fr;
  }
  @media screen and (max-width: 860px) {
    display: flex;
    height: auto;
    flex-direction: column;
  }
`;

export default ApproachPage;
