import React from "react";
import { expertiseCardData } from "../../store/storeAllData";
import { ExpertiseCard } from "../../components/layout-elements/cards/ExpertiseCard.component";
import { OfferSection } from "../../components/proposal/ProposalSection.component";
import useMobileScreen from "../../hooks/useMobileScreen";
import { MOBILE_WIDTH_SCREEN } from "../../utils";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

const WebDevelopmentPage = () => {
  const isMobile = useMobileScreen(MOBILE_WIDTH_SCREEN);

  return (
    <>
      <Helmet>
        <title>Web development - Kodin Soft</title>
        <meta
          name="description"
          content="Custom software development for web, mobile, & cloud. We translate user needs into software, ready to build your ideas with a team of experts."
        />
        <link rel="canonical" href="https://www.kodinsoft.net/expertise/web-development" />
      </Helmet>
      <Wrapper>
        <ExpertiseCard
          img={expertiseCardData.web.image}
          alt={expertiseCardData.web.alt}
          title={expertiseCardData.web.title}
          description={expertiseCardData.web.description}
          content={expertiseCardData.web.content}
        />
        <OfferSection isMobile={isMobile} />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 83%;
  margin: auto;
`;

export default WebDevelopmentPage;
