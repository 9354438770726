import React from "react";
import styled from "@emotion/styled";
import { HeroTitle } from "./HeroTitle.component";
import { HeroImage } from "../../../approach/HeroImage.component";
import { MaskCirclesImages } from "../../../approach/MaskCircles.component";

const Hero = () => {

  return (
    <Wrapper>
      <MaskCirclesImages />
      <HeroContainer>
        <HeroTitle/>
        <HeroImage />
      </HeroContainer>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background-color: #f5fcff;
`;
const HeroContainer = styled.div`
  height: 43.75rem;
  background: linear-gradient(
    83.27deg,
    #1244c5 8.43%,
    #225aeb 58.86%,
    #2490f4 106.22%
  );
  grid-template-columns: 1fr 1fr;
  display: grid;
  align-items: center;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 2fr 1fr;
  }
  @media screen and (max-width: 860px) {
    display: flex;
    height: auto;
    flex-direction: column;
  }
`;





export default Hero;
