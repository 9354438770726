import React from "react";
import styled from "@emotion/styled";
import arrowSVG from "../../assets/images/arrow-right-new.svg";
import { Link } from "react-router-dom";
import { ITechStackItem } from "../../model/interfaces/projects/IProjectData";

interface ParentProps {
  title: string;
  image: string;
  alt: string;
  intro: string;
  reverse: boolean;
  url: string;
  type?: "h3" | "h2";
  stack?: any;
}

export const ProjectCard = ({
  title,
  image,
  alt,
  intro,
  reverse = false,
  url,
  type = "h3",
  stack,
}: ParentProps) => {
  return (
    <Wrapper style={{ flexDirection: reverse ? "row-reverse" : "row" }}>
      <Image
        src={image}
        alt={alt}
        width="390"
        height="356"
        title="project-image-cover"
        loading="eager"
      />
      <Container>
        <Title as={type}>{title}</Title>
        <Description>{intro}</Description>
        <ButtonIconsContainer>
          <Button>
            <Link to={`/projects/${url}`}>
              Learn More
              <Icon
                src={arrowSVG}
                alt="arrow-icon"
                width="16"
                height="16"
                title="arrow"
                loading="lazy"
              />
            </Link>
          </Button>
          <StackWrapper>
            {stack?.map((el: ITechStackItem, index: number) => (
              <StackContainer key={index}>
                <TechImage
                  src={el.icon}
                  alt={el.alt}
                  title={el.icon}
                  width="100px"
                  height="100px"
                  loading="eager"
                />
                <TechName>{el.name}</TechName>
              </StackContainer>
            ))}
          </StackWrapper>
        </ButtonIconsContainer>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  /* height: 360px; */
  display: flex;
  min-width: 931px;
  margin-bottom: 50px;
  white-space: pre-line;

  @media screen and (max-width: 1024px) {
    min-width: 871px;
  }

  @media screen and (max-width: 935px) {
    flex-direction: column !important;
    height: auto;
    min-width: 310px;
  }

  border: 2px solid;
  transition: all 0.3s ease-in-out;
  --border-angle: 75deg;
  border-image: conic-gradient(
      from var(--border-angle),
      rgba(214, 222, 235, 1) 0%,
      rgba(248, 249, 249, 1) 25%,
      rgba(248, 249, 249, 1) 50%,
      rgba(214, 222, 235, 1) 75%
    )
    1;
  animation: spin 3s linear infinite;
`;

const Image = styled.img`
  width: 390px !important;
  /* height: 356px; */
  height: auto;
  object-fit: initial;

  @media screen and (max-width: 1024px) {
    width: 330px !important;
  }

  @media screen and (max-width: 935px) {
    width: 100% !important;
  }
`;

const Container = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
const Title = styled.h3`
  color: #1c232c;
  font-family: "Outfit";
  font-weight: 500;
  /* font-size: clamp(2rem, 4vw, 2.5rem); */
  line-height: 3.75rem;
  font-size: 35px;
  /* font-size: 2.5rem; */
  /* line-height: 3.75rem; */
`;
const Description = styled.p`
  color: #6d819d;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;

  /* font-size: clamp(1rem, 2vw, 1.25rem); */
  line-height: 1.75rem;
  font-size: 18px;

  /* font-size: 1.25rem; */
  /* line-height: 1.75rem; */
`;
const ButtonIconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1186px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;
const Button = styled.button`
  border: none;
  background-color: transparent;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.375rem;
  z-index: 12;
  background: linear-gradient(
    86.33deg,
    #1244c5 0.53%,
    #225aeb 50.08%,
    #2490f4 96.62%
  );
  -webkit-background-clip: text;
  background-clip: "text";
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: 624px) {
    margin-top: 20px;
  }
`;

const Icon = styled.img``;

const IconStack = styled.img`
  @media screen and (max-width: 624px) {
    display: none;
  }
`;

const StackWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 520px) {
    justify-content: space-between;
  }

  @media screen and (max-width: 408px) {
    justify-content: space-around;
  }
`;
const StackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TechImage = styled.img`
  width: 100px;
`;

const TechName = styled.p`
  white-space: nowrap;
  font-family: Outfit;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: -1rem;
`;

export default ProjectCard;
