import React, { useEffect, useState } from "react";

import NavItem from "./nav-item/NavItem";
import styled from "@emotion/styled";
import NavButton from "./nav-button/NavButton";
import NavIcon from "../../../assets/images/icons/ham-icon.png";
import CloseIcon from "../../../assets/images/icons/close-icon.png";
import LinkedinIcon from "../../../assets/images/icons/linkedin.svg";

import NewLogo from "../../../assets/images/Logotype.svg";
import { MOBILE_WIDTH_SCREEN } from "../../../utils";
import useMobileScreen from "../../../hooks/useMobileScreen";
import { Link, useLocation } from "react-router-dom";

const navigationMenu = [
  "Home",
  "Approach",
  "Who we are",
  "Expertise",
  "Services",
  "Projects",
];

const navigationRoutes = [
  "/",
  "/approach",
  "/about",
  "/expertise",
  "/services",
  "/projects",
];

const Navigation = ({ navbarScroll }: any) => {
  const [openMenu, setOpenMenu] = useState(false);
  const isMobile = useMobileScreen(MOBILE_WIDTH_SCREEN);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState("");
  const isInvertStyle = currentPage === "/" || currentPage === "/approach";

  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location]);

  const mobileMenu = () => {
    return (
      <DropdownNavWrapper onClick={() => setOpenMenu(!openMenu)}>
        <MobileViewNav>
          <LogoWrapper to="/">
            <img src={NewLogo} alt="kodin-logo" />
          </LogoWrapper>
          <CloseButton
            style={{ height: "18px", width: "18px" }}
            src={CloseIcon}
            alt="close"
            onClick={() => setOpenMenu(!openMenu)}
          />
        </MobileViewNav>
        <NavItemWrapper isMobile={isMobile}>
          {navigationRoutes.map((el, i) => (
            <NavItem key={i} link={el}>
              {navigationMenu[i]}
            </NavItem>
          ))}
          <li>
            <ContactNavItem href="#contact">Contact us</ContactNavItem>
          </li>
        </NavItemWrapper>
        <ContactUsButtonWrapper>
          <NavButton
            link="contact"
            style={{ width: "100%", marginRight: "0", position: "initial" }}
          >
            Get in touch
          </NavButton>
        </ContactUsButtonWrapper>

        <SocialMedia>
          <SocialMediaText>LinkedIn us</SocialMediaText>
          <SocialMediaButton href="https://www.linkedin.com/company/kodin-soft-ltd">
            <img src={LinkedinIcon} alt="kodin linkedin" />
          </SocialMediaButton>
        </SocialMedia>
      </DropdownNavWrapper>
    );
  };

  const changeNavigationView = () => {
    if (isMobile) {
      return (
        <NavbarWrapper id="navigation">
          <LogoWrapper
            to="/"
            is-invert-style={isInvertStyle.toString()}
            is-scrolling={navbarScroll.toString()}
          >
            <img src={NewLogo} alt="kodin-logo" loading="eager" />
          </LogoWrapper>
          <HamIcon>
            <img
              src={NavIcon}
              alt="Hamburger menu icon"
              onClick={() => setOpenMenu(!openMenu)}
            />
          </HamIcon>
        </NavbarWrapper>
      );
    }

    return (
      <NavbarWrapper id="navigation">
        <LogoWrapper
          to="/"
          is-invert-style={isInvertStyle.toString()}
          is-scrolling={navbarScroll.toString()}
        >
          <img
            src={NewLogo}
            alt="kodin-logo"
            title="Logo"
            loading="eager"
            width="auto"
            height="auto"
          />
        </LogoWrapper>
        <NavItemWrapper>
          {navigationRoutes.map((el, i) => (
            <NavItem
              key={i}
              link={el}
              isInvertStyle={isInvertStyle}
              isScrolling={navbarScroll}
            >
              {navigationMenu[i]}
            </NavItem>
          ))}
          <li>
            <ContactNavItem
              href="#contact"
              isInvertStyle={isInvertStyle}
              isScrolling={navbarScroll}
            >
              Contact us
            </ContactNavItem>
          </li>
        </NavItemWrapper>
        <NavButton
          link="contact"
          isInvertStyle={isInvertStyle}
          isScrolling={navbarScroll}
        >
          Get in touch
        </NavButton>
      </NavbarWrapper>
    );
  };

  return <>{openMenu ? mobileMenu() : changeNavigationView()}</>;
};

const NavbarWrapper = styled.nav`
  position: relative;
  width: 83%;
  margin: 0 auto;
  padding: 1.2rem;
  background: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: row;
  align-items: center;

  @media all and (max-width: 1024px) {
    padding: 2rem;
  }

  @media all and (max-width: 440px) {
    padding: 2rem;
    padding-left: 1.3rem;
  }
`;

const NavItemWrapper = styled.ul<any>`
  margin: ${(props) => (props.isMobile ? "4rem auto" : "0 auto")};
  padding: 0;
  width: 60%;
  height: ${(props) => (props.isMobile ? "50%" : "fit-content")};
  list-style-type: none;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  justify-content: space-between;
  align-items: center;
`;

const ContactNavItem = styled.a<any>`
  font-family: "Outfit", sans-serif;
  color: ${(props) =>
    props.isInvertStyle && !props.isScrolling
      ? "rgba(207, 213, 223, 1)"
      : "rgba(136, 141, 153, 1)"};
  text-decoration: none;
  font-size: 0.9rem;
  line-height: 1rem;
  &:hover {
    color: #1c232c;
  }
`;

const LogoWrapper = styled(Link)<{
  "is-invert-style"?: string;
  "is-scrolling"?: string;
}>`
  filter: ${(props) =>
    props["is-invert-style"] === "true" && props["is-scrolling"] === "false"
      ? "invert(100%)"
      : ""};

  @media all and (max-width: 1024px) {
    left: 4.5rem;
  }

  @media all and (max-width: 440px) {
    left: 1.2rem;
  }
`;

const HamIcon = styled.div`
  position: fixed;

  @media all and (max-width: 1024px) {
    right: 4.5rem;
  }

  @media all and (max-width: 440px) {
    right: 1.3rem;
  }
`;

const CloseButton = styled.img`
  position: fixed;
  right: 1.3rem;
`;

const MobileViewNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const DropdownNavWrapper = styled.div`
  background: #f5fcff;
  padding: 2em;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 9999;
  height: 95vh;
  width: 100%;
`;

const ContactUsButtonWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const SocialMedia = styled.div`
  width: fit-content;
  margin: 3rem auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SocialMediaText = styled.p`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
`;

const SocialMediaButton = styled.a`
  border: none;
  background: none;
  width: fit-content;
  margin-left: 1rem;
`;

export default Navigation;
